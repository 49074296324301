import React, { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { MRT_Localization_IT } from 'material-react-table/locales/it';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { CloseOutlined, ErrorOutlineRounded, ExpandMoreOutlined, InfoOutlined, SearchOutlined, WarningOutlined } from '@mui/icons-material';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { getLogs } from '../../client/apiClient';

import 'react-json-view-lite/dist/index.css';

dayjs.extend(timezone);
dayjs.extend(utc);

const Logs = () => {
    const [logs, setLogs] = React.useState([]);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const [logsNumber, setLogsNumber] = React.useState(0);
    const [containerName, setContainerName] = React.useState("mercitaliaprocess-log");
    const [className, setClassName] = React.useState("");
    const [logLevel, setLogLevel] = React.useState("all");
    const [severity, setSeverityLevel] = React.useState("all");
    const [message, setMessage] = React.useState("");
    const [method, setMethod] = React.useState("");
    const [serviceName, setServiceName] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10
    });

    React.useEffect(() => {
        setLoading(true);

        let newFilters = []

        if (className) {
            newFilters.push({
                "FieldName": "ClassName",
                "Value": className,
                "ComparisonType": "LIKE",
                "CaseInsensitive": true
            })
        }

        if (logLevel === 0 || (logLevel !== "" && logLevel !== "all")) {
            newFilters.push({
                "FieldName": "LogLevel",
                "Value": logLevel,
                "ComparisonType": "=",
                "CaseInsensitive": false
            })
        }

        if (severity === 0 || (severity !== "" && severity !== "all")) {
            newFilters.push({
                "FieldName": "LogSeverityLevel",
                "Value": severity,
                "ComparisonType": "=",
                "CaseInsensitive": false
            })
        }

        if (message) {
            newFilters.push({
                "FieldName": "Message",
                "Value": message,
                "ComparisonType": "LIKE",
                "CaseInsensitive": true
            })
        }

        if (method) {
            newFilters.push({
                "FieldName": "Method",
                "Value": method,
                "ComparisonType": "LIKE",
                "CaseInsensitive": true
            })
        }

        if (serviceName) {
            newFilters.push({
                "FieldName": "ServiceName",
                "Value": serviceName,
                "ComparisonType": "LIKE",
                "CaseInsensitive": true
            })
        }

        if (fromDate) {
            newFilters.push({
                "FieldName": "Timestamp",
                "Value": fromDate,
                "ComparisonType": ">=",
                "CaseInsensitive": false
            })
        }

        if (toDate) {
            newFilters.push({
                "FieldName": "Timestamp",
                "Value": toDate,
                "ComparisonType": "<=",
                "CaseInsensitive": false
            })
        }

        getLogs({
            "containerName": containerName ? containerName : "mercitaliaprocess-log",
            "filters": newFilters,
            "Offset": pagination.pageIndex,
            "Limit": pagination.pageSize
        })
            .then(res => {
                if (res && res.data && res.data.results) {
                    setLogs(res.data.results);
                    setLoading(false);
                    setLogsNumber(res.data.totalCount);
                }
            })
            .catch(e => {
                console.error("error", e)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.pageIndex, pagination.pageSize]);

    React.useEffect(() => {
        setLoading(true);

        let newFilters = []

        if (className) {
            newFilters.push({
                "FieldName": "ClassName",
                "Value": className,
                "ComparisonType": "LIKE",
                "CaseInsensitive": true
            })
        }

        if (logLevel === 0 || (logLevel !== "" && logLevel !== "all")) {
            newFilters.push({
                "FieldName": "LogLevel",
                "Value": logLevel,
                "ComparisonType": "=",
                "CaseInsensitive": false
            })
        }

        if (severity === 0 || (severity !== "" && severity !== "all")) {
            newFilters.push({
                "FieldName": "LogSeverityLevel",
                "Value": severity,
                "ComparisonType": "=",
                "CaseInsensitive": false
            })
        }

        if (message) {
            newFilters.push({
                "FieldName": "Message",
                "Value": message,
                "ComparisonType": "LIKE",
                "CaseInsensitive": true
            })
        }

        if (method) {
            newFilters.push({
                "FieldName": "Method",
                "Value": method,
                "ComparisonType": "LIKE",
                "CaseInsensitive": true
            })
        }

        if (serviceName) {
            newFilters.push({
                "FieldName": "ServiceName",
                "Value": serviceName,
                "ComparisonType": "LIKE",
                "CaseInsensitive": true
            })
        }

        if (fromDate) {
            newFilters.push({
                "FieldName": "Timestamp",
                "Value": fromDate,
                "ComparisonType": ">=",
                "CaseInsensitive": false
            })
        }

        if (toDate) {
            newFilters.push({
                "FieldName": "Timestamp",
                "Value": toDate,
                "ComparisonType": "<=",
                "CaseInsensitive": false
            })
        }

        getLogs({
            "containerName": containerName ? containerName : "mercitaliaprocess-log",
            "filters": newFilters,
            "Offset": pagination.pageIndex,
            "Limit": pagination.pageSize
        })
            .then(res => {
                if (res && res.data && res.data.results) {
                    setLogs(res.data.results);
                    setLoading(false);
                    setLogsNumber(res.data.totalCount);
                }
            })
            .catch(e => {
                console.error("error", e)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchLogs = () => {
        setLoading(true);

        setPagination({
            pageIndex: 0,
            pageSize: 10
        })

        let newFilters = []

        if (className) {
            newFilters.push({
                "FieldName": "ClassName",
                "Value": className,
                "ComparisonType": "LIKE",
                "CaseInsensitive": true
            })
        }

        if (logLevel === 0 || (logLevel !== "" && logLevel !== "all")) {
            newFilters.push({
                "FieldName": "LogLevel",
                "Value": logLevel,
                "ComparisonType": "=",
                "CaseInsensitive": false
            })
        }

        if (severity === 0 || (severity !== "" && severity !== "all")) {
            newFilters.push({
                "FieldName": "LogSeverityLevel",
                "Value": severity,
                "ComparisonType": "=",
                "CaseInsensitive": false
            })
        }

        if (message) {
            newFilters.push({
                "FieldName": "Message",
                "Value": message,
                "ComparisonType": "LIKE",
                "CaseInsensitive": true
            })
        }

        if (method) {
            newFilters.push({
                "FieldName": "Method",
                "Value": method,
                "ComparisonType": "LIKE",
                "CaseInsensitive": true
            })
        }

        if (serviceName) {
            newFilters.push({
                "FieldName": "ServiceName",
                "Value": serviceName,
                "ComparisonType": "LIKE",
                "CaseInsensitive": true
            })
        }

        if (fromDate) {
            newFilters.push({
                "FieldName": "Timestamp",
                "Value": fromDate,
                "ComparisonType": ">=",
                "CaseInsensitive": false
            })
        }

        if (toDate) {
            newFilters.push({
                "FieldName": "Timestamp",
                "Value": toDate,
                "ComparisonType": "<=",
                "CaseInsensitive": false
            })
        }

        getLogs({
            "containerName": containerName ? containerName : "mercitaliaprocess-log",
            "filters": newFilters,
            "Offset": pagination.pageIndex,
            "Limit": pagination.pageSize
        })
            .then(res => {
                if (res && res.data && res.data.results) {
                    setLogs(res.data.results);
                    setLoading(false);
                    setLogsNumber(res.data.totalCount);
                }
            })
            .catch(e => {
                console.error("error", e)
            })
    }

    const convertValueInIcon = (text) => {
        let icon = <InfoOutlined />

        if (text) {
            switch (text) {
                case "Error":
                    icon = <ErrorOutlineRounded sx={{ color: "red" }} />
                    break;
                case "Information":
                    icon = <InfoOutlined sx={{ color: "#0275d8" }} />
                    break;
                case "Warning":
                    icon = <WarningOutlined sx={{ color: "#f0ad4e" }} />
                    break;
                default:
                    break;
            }
        }

        return icon;
    }

    const convertSeverityValueInIcon = (text) => {
        let icon = <Chip sx={{ borderRadius: "5px" }} size='small' label="N.D." color="info" variant="outlined" />

        if (text) {
            switch (text) {
                case "High":
                    icon = <Chip sx={{ borderRadius: "5px" }} size='small' label="HIGH" color="error" variant="outlined" />
                    break;
                case "Medium":
                    icon = <Chip sx={{ borderRadius: "5px" }} size='small' label="MEDIUM" color="warning" variant="outlined" />
                    break;
                case "Low":
                    icon = <Chip sx={{ borderRadius: "5px" }} size='small' label="LOW" color="info" variant="outlined" />
                    break;
                default:
                    break;
            }
        }

        return icon;
    }

    const logColumns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                grow: true,
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word' }}>{cell.getValue()}</Box>
                )
            },
            {
                accessorKey: 'ServiceName',
                header: 'Service name',
                grow: true,
                size: 100,
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word' }}>{cell.getValue()}</Box>
                )
            },
            {
                accessorKey: 'ClassName',
                header: 'Class name',
                grow: true,
                size: 100,
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word' }}>{cell.getValue()}</Box>
                )
            },
            {
                accessorKey: 'Method',
                header: 'Method',
                grow: true,
                size: 100,
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word' }}>{cell.getValue()}</Box>
                )
            },
            {
                accessorKey: 'Data',
                header: 'Data',
                grow: true,
                size: 200,
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word', width: "-webkit-fill-available" }}>
                        {
                            JSON.stringify(cell.getValue()) === "null"
                                ? JSON.stringify(cell.getValue()).substring(0, 100)
                                : <>
                                    {JSON.stringify(cell.getValue()).substring(0, 100)}...
                                    <br />
                                    <Button sx={{ padding: 0, margin: 0 }} onClick={() => openDialog(cell.getValue())}>Show more</Button>
                                </>
                        }</Box>
                )
            },
            {
                accessorKey: 'LogLevel',
                header: 'Log level',
                grow: true,
                size: 100,
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word', display: "contents" }}>{convertValueInIcon(cell.getValue())}&nbsp;&nbsp;{cell.getValue()}</Box>
                )
            },
            {
                accessorKey: 'LogSeverityLevel',
                header: 'Severity level',
                grow: true,
                size: 100,
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word', display: "contents" }}>{convertSeverityValueInIcon(cell.getValue())}</Box>
                )
            },
            {
                accessorKey: 'Message',
                header: 'Message',
                grow: true,
                size: 300,
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word', width: "-webkit-fill-available" }}>{cell.getValue()}</Box>
                )
            },
            {
                accessorKey: 'Timestamp',
                header: 'Timestamp',
                grow: true,
                size: 150,
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word' }}>
                        {dayjs(cell.getValue()).format("DD/MM/YYYY HH:mm:ss (UTC Z)")}
                    </Box>
                )
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],);

    const tableLogs = useMaterialReactTable({
        muiTableBodyCellProps: { sx: { border: '1px solid #eee' } },
        initialState: {
            density: "compact",
            columnVisibility: {
                id: false,
            },
        },
        enableDensityToggle: false,
        localization: MRT_Localization_IT,
        columns: logColumns,
        data: logs,
        layoutMode: "grid",
        enablePagination: true,
        paginationDisplayMode: 'pages',
        manualPagination: true,
        muiPaginationProps: {
            color: 'primary',
            shape: 'rounded',
            rowsPerPageOptions: [5, 10, 20, 50, 100],
            showFirstButton: true,
            showLastButton: true,
            showRowsPerPage: true,
            variant: 'outlined'
        },
        rowCount: logsNumber,
        onPaginationChange: setPagination,
        muiTablePaperProps: {
            elevation: 3, //change the mui box shadow
            //customize paper styles
            sx: {
                // height: '50vh',
                overflowY: 'auto'
            },
        },
        state: {
            showLoadingOverlay: loading,
            pagination
        },
        renderTopToolbarCustomActions: ({ table }) =>
            <div style={{ display: "inline", paddingLeft: "10px", fontWeight: "lighter" }}>
                Sono stati trovati <Chip sx={{ borderRadius: "5px" }} size='small' label={logsNumber} color="success" variant="outlined" /> logs
            </div>,
        renderBottomToolbarCustomActions: ({ table }) => {
            let page = pagination.pageIndex;
            let size = pagination.pageSize;

            let min = 1;
            let max = logsNumber;

            if (page === 0) {
                max = size
                min = 1
            }
            else {
                min = (page * size) + 1
                max = (page * size) + size
            }

            if (max > logsNumber) max = logsNumber

            return <div style={{ display: "inline", fontWeight: "lighter", paddingLeft: "10px" }}>
                {min}-{max} of {logsNumber} logs
            </div>
        }
    });

    const openDialog = (content) => {
        setDialogContent(content);
        setOpen(true);
    };

    const closeDialog = () => {
        setDialogContent("");
        setOpen(false);
    };

    const reset = () => {
        setContainerName("mercitaliaprocess-log")
        setClassName("")
        setMessage("")
        setMethod("")
        setServiceName("")
        setLogLevel("all")
        setSeverityLevel("all")
        setFromDate(null)
        setToDate(null)
    }

    return <Box sx={{ margin: "20px 10px 20px 10px" }}>
        <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            onClose={closeDialog}
            open={open}
        >
            <DialogTitle id="alert-dialog-title">
                {"Data detail"}
                <IconButton
                    aria-label="close"
                    onClick={closeDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseOutlined />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ overflowY: "scroll", wordBreak: "break-all" }}>
                <JsonView data={dialogContent} shouldExpandNode={allExpanded} style={defaultStyles} />
            </DialogContent>
            <DialogActions>
                <Button color="info" variant='contained' onClick={() => { closeDialog(); }}>Chiudi</Button>
            </DialogActions>
        </Dialog>

        <Accordion elevation={3} defaultExpanded={true} key={"logs"} sx={{ marginBottom: "10px" }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>Ricerca logs</AccordionSummary>
            <AccordionDetails>
                <Box sx={{ padding: "10px" }}>
                    <TextField sx={{ marginRight: "10px", maxWidth: 200, minWidth: 200 }} id="container-name" label="Container name" variant="outlined" onChange={(event) => setContainerName(event.target.value)} value={containerName} />

                    <LocalizationProvider adapterLocale='it' dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Start date"
                            onChange={(val) => setFromDate(val)}
                            sx={{ height: "56px", marginRight: "10px", width: 200 }}
                            // timezone="UTC"
                            value={fromDate}
                            disableFuture={true}
                        />
                        <DateTimePicker
                            minDateTime={fromDate}
                            label="End date"
                            onChange={(val) => setToDate(val)}
                            sx={{ height: "56px", marginRight: "10px", width: 200 }}
                            // timezone="UTC"
                            value={toDate}
                        />
                    </LocalizationProvider>

                    <br /><br />

                    <TextField sx={{ marginRight: "10px", maxWidth: 200, minWidth: 200 }} id="service" label="Service name" variant="outlined" onChange={(event) => setServiceName(event.target.value)} value={serviceName} />
                    <TextField sx={{ marginRight: "10px", maxWidth: 200, minWidth: 200 }} id="class-name" label="Class name" variant="outlined" onChange={(event) => setClassName(event.target.value)} value={className} />
                    <TextField sx={{ marginRight: "10px", maxWidth: 200, minWidth: 200 }} id="method" label="Method" variant="outlined" onChange={(event) => setMethod(event.target.value)} value={method} />
                    <TextField sx={{ marginRight: "10px", maxWidth: 200, minWidth: 200 }} id="message" label="Message" variant="outlined" onChange={(event) => setMessage(event.target.value)} value={message} />

                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel id="log-level-label">Log Level</InputLabel>
                        <Select
                            id="log-level"
                            label="log-level"
                            onChange={(event) => setLogLevel(event.target.value)}
                            sx={{ marginRight: "10px", height: "56px" }}
                            value={logLevel}
                        >
                            <MenuItem value={"all"}>All levels</MenuItem>
                            <MenuItem value={0}>Information</MenuItem>
                            <MenuItem value={1}>Warning</MenuItem>
                            <MenuItem value={2}>Error</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel id="severity-label">Severity</InputLabel>
                        <Select label="severity" sx={{ marginRight: "10px", height: "56px" }} id="log-level" variant="outlined" onChange={(event) => setSeverityLevel(event.target.value)} value={severity}>
                            <MenuItem value={"all"}>All severity levels</MenuItem>
                            <MenuItem value={0}>Low</MenuItem>
                            <MenuItem value={1}>Medium</MenuItem>
                            <MenuItem value={2}>High</MenuItem>
                        </Select>
                    </FormControl>


                </Box>
            </AccordionDetails>
            <AccordionActions>
                <Button startIcon={<SearchOutlined />} sx={{ marginRight: "10px" }} variant='contained' onClick={(event) => { event.preventDefault(); searchLogs(); }}>Cerca</Button>
                <Button startIcon={<CloseOutlined />} sx={{ marginRight: "10px" }} variant="outlined" color='error' onClick={reset}>Reset</Button>
            </AccordionActions>
        </Accordion>
        <MaterialReactTable sx={{ border: "1px solid lightgray" }} table={tableLogs} />
    </Box>
};

export default Logs;