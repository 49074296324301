import { CheckBoxRounded, Close, ExpandMoreOutlined, Lock, RefreshOutlined, RemoveOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, LinearProgress, Snackbar, Tooltip, Typography } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_IT } from 'material-react-table/locales/it';
import { useConfirm } from "material-ui-confirm";
import { Fragment, useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";
import { useRecoilState, useRecoilValue } from "recoil";

import { t } from "i18next";
import { confirmRID, getProcessMail, startProcessIADetail, updateProcess } from "../../client/apiClient";
import { checkRIDAtom, selectedMailAtom, sse } from "../../states/wizardState";
import BackdropLoading from "../utils/BackdropLoading";
import EditFieldDialog from "./editFieldDialog";
import EditTargaUTIDialog from "./editTargaUTIDialog";
import RIDDialog from "./ridDialog";

const defaultWagonFieldList = [
    "WagonNumber",
    "WagonMass",
    "WagonLength",
    "WagonAxleNumber",
    "WagonDangerousGoodsFlag",
    "WagonWasteFlag",
    "WagonLoadingStatus",
    "WagonSeals",
    "WagonNumberOfSeals"
]

const defaultCargoFieldList = [
    "NHM",
    "CodificaUIRR",
    "CodificaUTI",
    "TargaUTI",
    "Prefix",
    "Number",
    "checkDigit",
    "Tare",
    "Height",
    "Length",
    "Width",
    "UtiSeals",
    "NumberOfSeals",
    "GrossMass",
    "Info"
]

const defaultGoodList = [
    "NHM",
    "GrossMass",
    "Length",
    "AVVKey",
    "Info"
]

const defaultRIDFieldList = [
    "WagonNumber",
    "NHM",
    "TargaUTI",

    "UNPrg",
    "UNNumber",
    "Class",
    "ClassificationCode",
    "HazardNumber"
    /*
    "LawYear",
    "HazardNumber",
    "Class",
    "ClassificationCode",
    "DangerLabel",
    "DangerLabel2",
    "DangerLabel3",
    "DangerLabel4",
    "DangerLabel5",
    "PackingGroup",
    "UNNumber",
    "Weight",
    "WeightExplosiveMass"*/
]

const defaultVehicleFieldList = [
    "NumberPlate",
    "CountryCode"
]

const ExtractionWagonsPage = () => {
    const [selectedMail, setSelectedMail] = useRecoilState(selectedMailAtom);
    const sseClient = useRecoilValue(sse);

    const [attachments, setAttachments] = useState([]);

    const [filteredRID, setFilteredRID] = useState(false);

    // RID per la convalida
    const [checkRID, setCheckRID] = useRecoilState(checkRIDAtom);
    const [showRIDPopup, setShowRIDPopup] = useState(false);
    const [extractedRID, setExtractedRID] = useState([]);
    const { nextStep } = useWizard();

    const [wagonStatus, setWagonStatus] = useState("");
    const [snackbarStatus, setSnackBarStatus] = useState(false);
    const [snackbarMessage, setSnackBarMessage] = useState("");
    const [disabledFields, setDisabledFields] = useState([]);

    const [msgLoading, setMsgLoading] = useState();

    const { activeStep } = useWizard();
    const confirm = useConfirm();

    //paginazione
    const [paginationAttachment, setPaginationAttachment] = useState();

    useEffect(() => {
        if (selectedMail) {
            getMailDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //estrazione RID per eventuale convalida si scatena alla pressione del tasto avanti del footer
    useEffect(() => {
        if (checkRID > 0) {

            setCheckRID(0);

            if (extractedRID.length > 0)
                setShowRIDPopup(true)
            else {
                setShowRIDPopup(false);
                nextStep();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkRID]);

    useEffect(() => {
        let extractedRID = extractRIDToValidate(attachments)
        setExtractedRID(extractedRID);
        if (extractedRID.length === 0 && showRIDPopup) {
            setShowRIDPopup(false);
            nextStep();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachments]);

    const refresh = () => {
        setWagonStatus("");
        getMailDetail();
    }

    //funzione di estrazione RID per popup di validazione
    function extractRIDToValidate(attachs) {
        const result = [];

        let keys = defaultRIDFieldList;

        attachs.forEach((contentDetail, contentIndex) => {
            contentDetail.attach_detail.forEach((attachDetail, attachIndex) => {
                attachDetail.Cargo.forEach((cargo, cargoIndex) => {
                    cargo.Goods.forEach((good, goodIndex) => {
                        if (good.RID && good.RID.length > 0) {
                            good.RID.forEach((rid, ridIndex) => {

                                if (rid.verify && (rid.verify.VALIDATED === true || rid.verify.DISCARDED === true)) {
                                    return;
                                }

                                // recupero il wagon Number
                                let wagon = attachDetail;
                                let wn = "";
                                if (wagon.modified.WagonNumber && wagon.modified.WagonNumber !== "")
                                    wn = wagon.modified.WagonNumber;
                                else if (wagon.orfeus.WagonNumber && wagon.orfeus.WagonNumber !== "")
                                    wn = wagon.orfeus.WagonNumber;
                                else if (wagon.extracted.WagonNumber && wagon.extracted.WagonNumber !== "")
                                    wn = wagon.extracted.WagonNumber;
                                //

                                //recupero nhm code e targa UTI
                                let nhmCode = "";
                                let targaUTI = "";

                                if (cargo.UtiRola.modified.NHM){
                                    nhmCode = cargo.UtiRola.modified.NHM;
                                }
                                else if (cargo.UtiRola.orfeus.NHM){
                                    nhmCode = cargo.UtiRola.orfeus.NHM;
                                }
                                else if (cargo.UtiRola.extracted.NHM){
                                    nhmCode = cargo.UtiRola.extracted.NHM;
                                }

                                if (cargo.UtiRola.modified.Number)
                                    targaUTI = cargo.UtiRola.modified.Prefix + cargo.UtiRola.modified.Number + cargo.UtiRola.modified.checkDigit;
                                else if (cargo.UtiRola.orfeus.Number)
                                    targaUTI = cargo.UtiRola.orfeus.Prefix + cargo.UtiRola.orfeus.Number + cargo.UtiRola.orfeus.checkDigit;
                                else if (cargo.UtiRola.extracted.Number)
                                    targaUTI = cargo.UtiRola.extracted.Prefix + cargo.UtiRola.extracted.Number + cargo.UtiRola.extracted.checkDigit;
                                //


                                let retObj = {};

                                keys.forEach(key => {
                                    //Recupero prima il "modificato", poi "orfeus" ed infine "extracted"
                                    if (rid.modified[key]) {
                                        retObj[key] = rid.modified[key]
                                    }
                                    else if (rid.orfeus[key]) {
                                        retObj[key] = rid.orfeus[key] + " (orfeus)"
                                    }
                                    else if (rid.extracted[key]) {
                                        retObj[key] = rid.extracted[key]
                                    }
                                    else retObj[key] = ""
                                });

                                retObj["WagonNumber"] = wn;
                                retObj["TargaUTI"] = targaUTI;
                                retObj["NHM"] = nhmCode;

                                retObj = {
                                    ...retObj,
                                    ...rid,
                                    indAttach: contentIndex,
                                    indWagon: attachIndex,
                                    indCargo: cargoIndex,
                                    indGood: goodIndex,
                                    indRid: ridIndex
                                }

                                result.push(retObj);
                            });
                        }
                    });
                });
            });
        });

        return result;
    }

    // verifica se un vagone ha un RID o meno
    function hasAtLeastOneRID(data) {
        return data.extracted.WagonDangerousGoodsFlag === 'Y';
    }

    // get rid by id
    function updateRIDAttachments(modified, idRid, validate, discard) {
        let modifiedData = JSON.parse(JSON.stringify(attachments));

        modifiedData.forEach((contentDetail, contentIndex) => {
            contentDetail.attach_detail.forEach((attachDetail, attachIndex) => {
                attachDetail.Cargo.forEach((cargo, cargoIndex) => {
                    cargo.Goods.forEach((good, goodIndex) => {
                        if (good.RID && good.RID.length > 0) {
                            good.RID.forEach((rid, ridIndex) => {
                                if (rid.IdRID === idRid) {
                                    rid.modified = modified;
                                    rid.verify.VALIDATED = validate;
                                    rid.verify.DISCARDED = discard;
                                }
                            });
                        }
                    });
                });
            });
        });

        return modifiedData;
    }

    const removeWagon = (row) => {

        confirm({
            cancellationText: t(`shared.modals.cancel`),
            cancellationButtonProps: { color: "inherit", variant: "outlined" },
            confirmationText: "Procedi",
            confirmationButtonProps: { color: "primary", variant: "contained" },
            description: <> Rimuovere il Vagone: {row.WagonNumber} ? <br /></>,
            title: "Rimozione Vagone"
        })
            .then(() => {

                let modifiedData = JSON.parse(JSON.stringify(attachments));
                modifiedData[row.indAttach].attach_detail.splice(row.indWagon, 1);

                setMsgLoading("Modifica in corso");
                updateProcess({ "id": selectedMail.id_process, "content_detail": modifiedData }).then(
                    res => {
                        if (res && res.data) {
                            setAttachments(modifiedData);

                            //APERTURA SNACKBAR
                            if (!snackbarStatus) setSnackBarStatus(true)
                            setSnackBarMessage("Modifica Effettuata")
                        }
                    }
                ).catch(() => { /*ANNULLA*/ }).finally(() => setMsgLoading());

            })
            .catch(() => { });


    }

    const editDistintaField = (row, value) => {
        let modifiedData = JSON.parse(JSON.stringify(attachments));

        // se update RID
        if (row.indRid != null) {
            let rid = modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].Goods[row.indGood].RID[row.indRid].modified;

            if (!rid)
                modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].Goods[row.indGood].RID[row.indRid].modified = {};

            modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].Goods[row.indGood].RID[row.indRid].modified[row.key] = value;
        }
        // se update Good
        if (row.indGood != null) {
            let good = modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].Goods[row.indGood].Good.modified;

            if (!good)
                modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].Goods[row.indGood].Good[row.indGood].modified = {};

            modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].Goods[row.indGood].Good.modified[row.key] = value;
        }
        // se update veicolo
        else if (row.indVehicle != null) {
            let vehicle = modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].Vehicles[row.indVehicle].modified;

            if (!vehicle)
                modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].Vehicles[row.indVehicle].modified = {};

            modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].Vehicles[row.indVehicle].modified[row.key] = value;
        }
        // se update utiRola
        else if (row.utiRola != null) {

            let utiRola = modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].UtiRola.modified;

            if (!utiRola)
                modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].UtiRola.modified = {};

            if (["IsUTI", "IsRola"].includes(row.key)) {
                if (value === "true")
                    modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].UtiRola.modified[row.key] = true;
                else if (value === "false")
                    modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].UtiRola.modified[row.key] = false;
            }
            else {
                // per l'update di targa UTI
                if (row.utiRola.key === 'TargaUTI') {
                    modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].UtiRola.modified["Prefix"] = value[0];
                    modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].UtiRola.modified["Number"] = value[1];
                    modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].UtiRola.modified["checkDigit"] = value[2];
                }
                else
                    modifiedData[row.indAttach].attach_detail[row.indWagon].Cargo[row.indCargo].UtiRola.modified[row.utiRola.key] = value;

            }


        }
        // se update wagon
        else if (row.indWagon != null)
            modifiedData[row.indAttach].attach_detail[row.indWagon].modified[row.key] = value;

        setMsgLoading("Modifica in corso");
        updateProcess({ "id": selectedMail.id_process, "content_detail": modifiedData }).then(
            res => {
                if (res && res.data) {
                    setAttachments(modifiedData);

                    //APERTURA SNACKBAR
                    if (!snackbarStatus) setSnackBarStatus(true)
                    setSnackBarMessage("Modifica Effettuata")
                }
            }
        ).catch(() => { /*ANNULLA*/ }).finally(() => setMsgLoading());
    }

    //#region WAGON
    function getWagonColumns(rows) {
        let columnList = [];
        let keys = defaultWagonFieldList;

        if (rows && rows[0] && Object.keys(rows[0]).length > 0 && rows[0].extracted) {
            keys.forEach(field => {
                columnList.push({
                    header: t("distinta.wagon." + field),
                    accessorKey: field,
                    size: 150,
                    Cell: ({ renderedCellValue, row }) => {

                        let valueToShow = renderedCellValue ? renderedCellValue.toString() : "---";

                        return <Box sx={{ display: "flex", justifyContent: "space-between", whiteSpace: "normal", paddingLeft: "2px", width: "100%", wordWrap: 'break-word', border: row.original.extracted[field] !== renderedCellValue ? '2px solid orange' : "none" }}>

                            <>
                                <span>
                                    {valueToShow}
                                    {row.original.modified && row.original.modified[field] ? <CheckBoxRounded color="info" style={{ paddingLeft: "10px", verticalAlign: "middle" }} /> : <></>}
                                </span>

                                {disabledFields.includes(field) ?
                                    <Tooltip title={t("tooltips.attributoNonEditabile")}>
                                        <Lock className='hover-icon' variant="text" />
                                    </Tooltip>
                                    :
                                    <EditFieldDialog
                                        label={t("distinta.wagon." + field)}
                                        field={field}
                                        row={row.original}
                                        onlyIcon={true}
                                        onUpdate={(row, value) => { editDistintaField(row, value) }}
                                    />
                                }

                            </>

                        </Box>
                    }
                })
            })
        }

        return columnList
    }

    function getWagonData(rows, indexAttach) {
        let retList = [];

        if (rows) {
            let keys = defaultWagonFieldList;

            rows.forEach((wagon, indexWagon) => {
                let retObj = {}

                if (wagon) {
                    keys.forEach(key => {
                        //Recupero prima il "modificato", poi "orfeus" ed infine "extracted"
                        if (wagon.modified[key]) {
                            retObj[key] = wagon.modified[key]
                        }
                        else if (wagon.orfeus[key]) {
                            retObj[key] = wagon.orfeus[key] + " (orfeus)"
                        }
                        else if (wagon.extracted[key]) {
                            retObj[key] = wagon.extracted[key]
                        }
                        else retObj[key] = ""
                    });

                    retObj = {
                        ...retObj,
                        ...wagon,
                        indAttach: indexAttach,
                        indWagon: indexWagon
                    }

                    retList.push(retObj)
                }

            })
        }
        return retList;
    }
    //#endregion

    //#region CARGO
    function getCargoColumns() {

        //nascondo questi 3 campi perchè mostro la targaUTI
        let fieldList = defaultCargoFieldList.filter(e => !["Prefix", "Number", "checkDigit"].includes(e));

        return fieldList.map(field => {
            return {
                header: t("distinta.cargo." + field),
                accessorKey: field,
                grow: true,
                size: 150,
                Cell: ({ renderedCellValue, row }) => {

                    let valueToShow = renderedCellValue ? renderedCellValue.toString() : "---";

                    let modified = row.original.utiRola.modified && row.original.utiRola.modified[field];

                    // per il confronto con eventuale modifica
                    if (field === 'TargaUTI') {
                        row.original.utiRola.extracted[field] = row.original.utiRola.extracted.Prefix + row.original.utiRola.extracted.Number + row.original.utiRola.extracted.checkDigit;
                        modified = row.original.utiRola.modified && (row.original.utiRola.modified["Prefix"] || row.original.utiRola.modified["Number"] || row.original.utiRola.modified["checkDigit"]);
                    }

                    if (field === "IsUTI" && renderedCellValue) {
                        valueToShow = "Container"
                    }
                    else if (field === "IsRola" && renderedCellValue) {
                        valueToShow = "Rola"
                    }

                    if (!["IsRola", "IsUTI"].includes(field)) {
                        valueToShow = valueToShow.toString();
                    }

                    return <Box sx={{ display: "flex", justifyContent: "space-between", whiteSpace: "normal", paddingLeft: "2px", width: "100%", wordWrap: 'break-word', border: row.original.utiRola.extracted[field] !== renderedCellValue ? '2px solid orange' : "none" }}>

                        <>
                            <span>
                                {valueToShow}
                                {modified ? <CheckBoxRounded color="info" style={{ paddingLeft: "10px", verticalAlign: "middle" }} /> : <></>}
                            </span>

                            {disabledFields.includes(field) ?
                                <Tooltip title={t("tooltips.attributoNonEditabile")}>
                                    <Lock className='hover-icon' variant="text" />
                                </Tooltip>
                                :

                                field === 'TargaUTI'
                                    ?
                                    <EditTargaUTIDialog
                                        label={t("distinta.cargo." + field)}
                                        field={field}
                                        rowToUpdate={row.original}
                                        row={row.original.utiRola}
                                        onUpdate={(row, value) => { editDistintaField(row, value) }}
                                    />
                                    :
                                    <EditFieldDialog
                                        label={t("distinta.cargo." + field)}
                                        field={field}
                                        rowToUpdate={row.original}
                                        row={row.original.utiRola}
                                        onlyIcon={true}
                                        onUpdate={(row, value) => { editDistintaField(row, value) }}
                                    />
                            }
                        </>

                    </Box>

                }
            }
        })
    }

    function getDataUtiRola(cargoList, indexAttach, indexWagon) {

        let retList = [];

        if (cargoList) {
            let keys = defaultCargoFieldList;

            cargoList.forEach((cargo, indexCargo) => {
                let retObj = {}

                if (cargo && cargo.UtiRola) {
                    keys.forEach(key => {
                        //Recupero prima il "modificato", poi "orfeus" ed infine "extracted"
                        if (cargo.UtiRola.modified[key]) {
                            retObj[key] = cargo.UtiRola.modified[key]
                        }
                        else if (cargo.UtiRola.orfeus[key]) {
                            retObj[key] = cargo.UtiRola.orfeus[key] + " (orfeus)"
                        }
                        else if (cargo.UtiRola.extracted[key]) {
                            retObj[key] = cargo.UtiRola.extracted[key]
                        }
                        else retObj[key] = ""
                    });

                    //costruisco targaUTI concatenando i seguenti campi
                    retObj["TargaUTI"] = retObj.Prefix + retObj.Number + retObj.checkDigit;

                    retObj = {
                        ...retObj,
                        utiRola: cargo.UtiRola,
                        indAttach: indexAttach,
                        indWagon: indexWagon,
                        indCargo: indexCargo
                    }

                    retList.push(retObj)
                }

            })
        }

        return retList;
    }
    //#endregion

    //#region GOODS
    function getGoodsColumns() {
        return defaultGoodList.map(field => {
            return {
                header: t("distinta.goods." + field),
                accessorKey: field,
                grow: true,
                size: 200,
                Cell: ({ renderedCellValue, row }) => {
                    let valueToShow = renderedCellValue ? renderedCellValue.toString() : "---";

                    return <Box sx={{ display: "flex", justifyContent: "space-between", whiteSpace: "normal", paddingLeft: "2px", width: "100%", wordWrap: 'break-word', border: row.original.extracted[field] !== renderedCellValue ? '2px solid orange' : "none" }}>

                        <>
                            <span>
                                {valueToShow}
                                {row.original.modified && row.original.modified[field] ? <CheckBoxRounded color="info" style={{ paddingLeft: "10px", verticalAlign: "middle" }} /> : <></>}
                            </span>
                            {disabledFields.includes(field) ?
                                <Tooltip title={t("tooltips.attributoNonEditabile")}>
                                    <Lock className='hover-icon' variant="text" />
                                </Tooltip>
                                :
                                <EditFieldDialog
                                    label={t("distinta.goods." + field)}
                                    field={field}
                                    row={row.original}
                                    onlyIcon={true}
                                    onUpdate={(row, value) => { editDistintaField(row, value) }}
                                />}
                        </>
                    </Box>
                }
            }
        })
    }

    function getDataGood(goods, indexAttach, indexWagon, indexCargo) {
        let retList = [];

        if (goods) {
            // let keys = goods && goods[0] && goods[0].Good && goods[0].Good.extracted ? Object.keys(goods[0].Good.extracted) : defaultGoodList;
            let keys = defaultGoodList;

            goods.forEach((good, indexGood) => {
                let retObj = {}

                if (good.Good) {
                    keys.forEach(key => {
                        //Recupero prima il "modificato", poi "orfeus" ed infine "extracted"
                        if (good.Good.modified[key]) {
                            retObj[key] = good.Good.modified[key]
                        }
                        else if (good.Good.orfeus[key]) {
                            retObj[key] = good.Good.orfeus[key] + " (orfeus)"
                        }
                        else if (good.Good.extracted[key]) {
                            retObj[key] = good.Good.extracted[key]
                        }
                        else retObj[key] = ""
                    });

                    retObj = {
                        ...retObj,
                        ...good.Good,
                        indAttach: indexAttach,
                        indWagon: indexWagon,
                        indCargo: indexCargo,
                        indGood: indexGood
                    }

                    retList.push(retObj)
                }
            })
        }

        return retList;
    }
    //#endregion

    //#region RID
    function getRIDColumns() {
        return defaultRIDFieldList.map(field => {
            return {
                header: t("distinta.rid." + field),
                accessorKey: field,
                grow: true,
                size: 150,
                Cell: ({ renderedCellValue, row }) => {
                    let valueToShow = renderedCellValue ? renderedCellValue.toString() : "---";

                    return <Box sx={{ display: "flex", justifyContent: "space-between", whiteSpace: "normal", paddingLeft: "2px", width: "100%", wordWrap: 'break-word',
                         border: (!['WagonNumber', 'NHM', 'TargaUTI'].includes(field) && row.original.extracted[field] !== renderedCellValue) ? '2px solid orange' : "none" }}>

                        <>
                            <span>
                                {valueToShow}
                                {row.original.modified && row.original.modified[field] ? <CheckBoxRounded color="info" style={{ paddingLeft: "10px", verticalAlign: "middle" }} /> : <></>}
                            </span>

                            {
                                /*
                                disabledFields.includes(field) ?
                                    <Tooltip title={t("tooltips.attributoNonEditabile")}>
                                        <Lock className='hover-icon' variant="text" />
                                    </Tooltip>
                                    :
                                    <EditFieldDialog
                                        label={t("distinta.rid." + field)}
                                        field={field}
                                        row={row.original}
                                        onlyIcon={true}
                                        onUpdate={(row, value) => { editDistintaField(row, value) }}
                                    />
                                */
                            }
                        </>

                    </Box>
                }
            }
        })
    }

    function getRIDData(rids, indexAttach, indexWagon, indexCargo, indexGood) {

        // recupero il wagon Number;
        let wagon = attachments[indexAttach].attach_detail[indexWagon];
        let wn = "";
        if (wagon.modified.WagonNumber)
            wn = wagon.modified.WagonNumber;
        else if (wagon.orfeus.WagonNumber)
            wn = wagon.orfeus.WagonNumber;
        else if (wagon.extracted.WagonNumber)
            wn = wagon.extracted.WagonNumber;
        //

        //recupero nhm code e targa UTI
        let cargo = wagon.Cargo[indexCargo];
        let nhmCode = "";
        let targaUTI = "";

        if (cargo.UtiRola.modified.NHM)
            nhmCode = cargo.UtiRola.modified.NHM;
        else if (cargo.UtiRola.orfeus.NHM)
            nhmCode = cargo.UtiRola.orfeus.NHM;
        else if (cargo.UtiRola.extracted.NHM)
            nhmCode = cargo.UtiRola.extracted.NHM;

        if (cargo.UtiRola.modified.Number)
            targaUTI = cargo.UtiRola.modified.Prefix + cargo.UtiRola.modified.Number + cargo.UtiRola.modified.checkDigit;
        else if (cargo.UtiRola.orfeus.Number)
            targaUTI = cargo.UtiRola.orfeus.Prefix + cargo.UtiRola.orfeus.Number + cargo.UtiRola.orfeus.checkDigit;
        else if (cargo.UtiRola.extracted.Number)
            targaUTI = cargo.UtiRola.extracted.Prefix + cargo.UtiRola.extracted.Number + cargo.UtiRola.extracted.checkDigit;
        //

        let retList = [];

        if (rids) {
            let keys = defaultRIDFieldList;

            rids.forEach((rid, index) => {
                let retObj = {}

                if (rid) {
                    keys.forEach(key => {
                        //Recupero prima il "modificato", poi "orfeus" ed infine "extracted"
                        if (rid.modified[key]) {
                            retObj[key] = rid.modified[key]
                        }
                        else if (rid.orfeus[key]) {
                            retObj[key] = rid.orfeus[key] + " (orfeus)"
                        }
                        else if (rid.extracted[key]) {
                            retObj[key] = rid.extracted[key]
                        }
                        else retObj[key] = ""
                    });

                    retObj["WagonNumber"] = wn;
                    retObj["TargaUTI"] = targaUTI;
                    retObj["NHM"] = nhmCode;

                    retObj = {
                        ...retObj,
                        ...rid,
                        indAttach: indexAttach,
                        indWagon: indexWagon,
                        indCargo: indexCargo,
                        indGood: indexGood,
                        indRid: index
                    }

                    retList.push(retObj)
                }
            })
        }

        return retList;
    }
    //#endregion

    //#region VEHICLE
    function getVehicleColumns() {
        return defaultVehicleFieldList.map(field => {
            return {
                header: t("distinta.wehicle." + field),
                accessorKey: field,
                grow: true,
                size: 150,
                Cell: ({ renderedCellValue, row }) => {
                    let valueToShow = renderedCellValue ? renderedCellValue.toString() : "---";

                    return <Box sx={{ display: "flex", justifyContent: "space-between", whiteSpace: "normal", paddingLeft: "2px", width: "100%", wordWrap: 'break-word', border: row.original.extracted[field] !== renderedCellValue ? '2px solid orange' : "none" }}>

                        <>
                            <span>
                                {valueToShow}
                                {row.original.modified && row.original.modified[field] ? <CheckBoxRounded color="info" style={{ paddingLeft: "10px", verticalAlign: "middle" }} /> : <></>}
                            </span>
                            {disabledFields.includes(field) ?
                                <Tooltip title={t("tooltips.attributoNonEditabile")}>
                                    <Lock className='hover-icon' variant="text" />
                                </Tooltip>
                                :
                                <EditFieldDialog
                                    label={t("distinta.wehicle." + field)}
                                    field={field}
                                    row={row.original}
                                    onlyIcon={true}
                                    onUpdate={(row, value) => { editDistintaField(row, value) }}
                                />
                            }
                        </>

                    </Box>
                }
            }
        })
    }

    function getVehiclesData(vehicles, indexAttach, indexWagon, indexCargo) {
        let retList = [];

        if (vehicles) {
            let keys = defaultVehicleFieldList;

            vehicles.forEach((vehicle, index) => {
                let retObj = {}

                if (vehicle) {
                    keys.forEach(key => {
                        //Recupero prima il "modificato", poi "orfeus" ed infine "extracted"
                        if (vehicle.modified[key]) {
                            retObj[key] = vehicle.modified[key]
                        }
                        else if (vehicle.orfeus[key]) {
                            retObj[key] = vehicle.orfeus[key] + " (orfeus)"
                        }
                        else if (vehicle.extracted[key]) {
                            retObj[key] = vehicle.extracted[key]
                        }
                        else retObj[key] = ""
                    });

                    retObj = {
                        ...retObj,
                        ...vehicle,
                        indAttach: indexAttach,
                        indWagon: indexWagon,
                        indCargo: indexCargo,
                        indVehicle: index
                    }

                    retList.push(retObj)
                }
            })
        }

        return retList;
    }
    //#endregion

    useEffect(() => {
        if (sseClient) {
            console.log("avvio run_detail_extractor listener");
            sseClient.addEventListener('run_detail_extractor', runDetailExtractorListener);

            return () => {
                console.log("rimozione run_detail_extractor listener");
                sseClient.removeEventListener("run_detail_extractor", runDetailExtractorListener);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sseClient]);

    const runDetailExtractorListener = (event) => {

        if (event.data) {
            let data = JSON.parse(event.data);
            if (data.id === selectedMail.id && data.message.type_elaboration === 'distinta') {
                if (data.message.in_elaboration === false || (data.message.in_elaboration === true && data.message.perc_avz >= 100)) {
                    refresh();
                } else {
                    setWagonStatus(data.message.msg);
                }
            }
        }
    }

    const formatDataAttachment = (data) => {
        let formattedData = []

        data.forEach(d => {
            if (Array.isArray(d.attach_detail) && d.attach_detail.length > 0) {
                let name = d.attach_name

                formattedData.push({
                    attach_name: name,
                    attach_detail: d.attach_detail
                })
            }
        })

        return formattedData
    }

    const updateRID = (modified, idRid, validate, discard) => {

        let updateInput = {};
        updateInput.id_mail = selectedMail.id_mail;
        updateInput.id_rid = idRid;
        
        updateInput.data={};
        updateInput.data.VALIDATED = validate;
        updateInput.data.DISCARDED = discard;
        updateInput.data.modified = modified;

        confirmRID(updateInput).then(
            res => {
                if (res && res.data) {

                    let modifiedData = updateRIDAttachments(modified, idRid, validate, discard);
                    setAttachments(modifiedData);

                    //APERTURA SNACKBAR
                    if (!snackbarStatus) setSnackBarStatus(true)
                    setSnackBarMessage("Modifica Effettuata")
                }
            }
        ).catch(() => { /*ANNULLA*/ });
    }

    const getMailDetail = () => {
        setWagonStatus("Caricamento dati...");

        getProcessMail(selectedMail.id_mail, activeStep).then(res => {

            if (res.data) {
                //refresh dello stato
                let sm = { ...selectedMail };
                sm.current_step = res.data.current_step;
                if (res.data.id_process)
                    sm.id_process = res.data.id_process;
                sm.available = false;
                setSelectedMail(sm);
            }

            if (res.data && res.data.status_elaboration && res.data.status_elaboration.in_elaboration && res.data.status_elaboration.type_elaboration === "distinta") {
                setWagonStatus(res.data.status_elaboration.msg);
            }

            else if (res && res.data.content_detail && res.data.content_detail.length > 0) {
                setDisabledFields(res.data.Distinta_EditForm_Disabled_Fields);

                setAttachments(formatDataAttachment(res.data.content_detail));

                let pagination = [];
                res.data.content_detail.map((a, index) => {
                    pagination[index] = {
                        pageIndex: 0,
                        pageSize: 10
                    }
                });
                setPaginationAttachment(pagination);

                setWagonStatus("OK");
            }
            else
                setWagonStatus("Dati Distinta Carri non disponibili.");
        })
            .catch(() => { /*ANNULLA*/ });
    }

    const restartIADetail = () => {
        confirm({
            cancellationText: "Annulla",
            cancellationButtonProps: { color: "inherit", variant: "outlined" },
            confirmationText: "Procedi",
            confirmationButtonProps: { color: "primary", variant: "contained" },
            description: <> Avviare la rielaborazione della distinta? <br /><br /> Attenzione, tutti i dati presenti verranno cancellati. </>,
            title: "Rielaborazione distinta"
        })
            .then(() => {
                startProcessIADetail(selectedMail.id_mail)
                    .catch(() => { refresh() });
            })
            .catch(() => { /*ANNULLA*/ });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarStatus(false);
    };

    if (wagonStatus === "OK") {

        return (<Box sx={{ m: 1 }}>
            {msgLoading && <BackdropLoading message={msgLoading} />}
            <Box sx={{ textAlign: "right" }}>

                <FormControlLabel control={<Checkbox onChange={e => setFilteredRID(e.target.checked)} />} label="Filtra Solo RID" />

                <Tooltip title={t("tooltips.rielaboraDistinta")}>
                    <Button color="info" variant="contained" startIcon={<RefreshOutlined />} sx={{ mb: 2, mt: 2, mr: 1 }} onClick={restartIADetail}>Rielabora DISTINTA</Button>
                </Tooltip>
            </Box>

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarStatus}
                autoHideDuration={3000}
                key={"modifySuccess"}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Dialog
                maxWidth={"xl"}
                open={showRIDPopup}
                onClose={() => { setShowRIDPopup(false) }}
            >
                <DialogTitle id="alert-dialog-title">RID da convalidare</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setShowRIDPopup(false)}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'red',
                    })}
                >
                    <Close />
                </IconButton>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Alert variant="filled" severity="warning">Per proseguire occorre convalidare o meno i seguenti RID</Alert>
                        <MaterialReactTable
                            columns={getRIDColumns()}
                            data={extractedRID}
                            enableColumnFilters={false}
                            enableColumnResizing
                            enableDensityToggle={false}
                            enableExpandAll={false}
                            enableExpanding={false}
                            enableFullScreenToggle={false}
                            enableGlobalFilter={false}
                            enableHiding={false}
                            initialState={{
                                density: "compact"
                            }}
                            layoutMode="grid"
                            localization={MRT_Localization_IT}
                            muiTableBodyCellProps={{ sx: { borderRight: '1px solid #eee' } }}
                            muiExpandButtonProps={({ row, table }) => ({
                                onClick: () => table.setExpanded({
                                    [row.id]: !row.getIsExpanded()
                                })
                            })}
                            enablePagination={false}
                            enableRowActions
                            renderRowActions={({ row }) => (
                                <RIDDialog rid={row.original} updateRID={updateRID} />
                            )}
                        />
                    </DialogContentText>
                </DialogContent>
                {/*
                <DialogActions>
                    <Button variant="contained" color="error" onClick={() => { updateAllRID(extractedRID, false) }}>Scarta Tutti</Button>
                    <Button variant="contained" color="success" onClick={() => { updateAllRID(extractedRID, true) }} autoFocus>
                        Convalida Tutti
                    </Button>
                </DialogActions>
                */}
            </Dialog>

            {attachments.map((a, indAttach) => {
                let tableData = getWagonData(a.attach_detail, indAttach);

                if (paginationAttachment) {
                    let paginetedTableData = tableData.slice(paginationAttachment[indAttach].pageIndex * paginationAttachment[indAttach].pageSize,
                        (paginationAttachment[indAttach].pageIndex + 1) * paginationAttachment[indAttach].pageSize);

                    return <Accordion key={"attach" + indAttach}>
                        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                            <Box sx={{ fontWeight: 'bold', mr: 1 }}>Allegato: </Box>{a.attach_name}
                        </AccordionSummary>
                        <AccordionDetails>
                            <MaterialReactTable
                                columns={getWagonColumns(a.attach_detail)}
                                data={filteredRID ? tableData : paginetedTableData}
                                rowCount={tableData.length}
                                enableColumnResizing
                                enableDensityToggle={false}
                                enableExpanding={false}
                                enableExpandAll={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    style: { display: filteredRID ? hasAtLeastOneRID(row.original) ? 'flex' : 'none' : 'flex' }
                                })}
                                initialState={{
                                    density: "compact",
                                }}
                                layoutMode="grid"
                                state={{ pagination: paginationAttachment[indAttach] }}
                                onPaginationChange={(updater) => {
                                    setPaginationAttachment((prevPagination) => {
                                        const newPagination = updater(prevPagination[indAttach]);
                                        let pag = [...prevPagination];
                                        pag[indAttach] = newPagination;
                                        return pag;
                                    });
                                }
                                }
                                manualPagination
                                enablePagination={filteredRID ? false : true}
                                localization={MRT_Localization_IT}
                                muiTableBodyCellProps={{ sx: { borderRight: '1px solid #eee' } }}
                                muiExpandButtonProps={({ row, table }) => ({
                                    onClick: () => table.setExpanded({
                                        [row.id]: !row.getIsExpanded()
                                    })
                                })}
                                renderDetailPanel={({ row }) => {
                                    let indexWagon = row.original.indWagon;
                                    let wagonRow = row;

                                    let dataUtiRola = getDataUtiRola(row.original.Cargo, indAttach, indexWagon);

                                    let dataUTI = dataUtiRola.filter(item => item.utiRola.modified.IsUTI);
                                    let dataNoUTI = dataUtiRola.filter(item => !item.utiRola.modified.IsUTI);

                                    if (dataUTI.length > 0) {
                                        return <Box>
                                            <Fragment key={a.attach_name + "_wagon_" + indexWagon}>
                                                <>
                                                    <MaterialReactTable
                                                        columns={getCargoColumns()}
                                                        data={dataUTI}
                                                        enableColumnFilters={false}
                                                        enableColumnResizing
                                                        enableDensityToggle={false}
                                                        enableExpandAll={false}
                                                        enableExpanding={false}
                                                        enableFullScreenToggle={false}
                                                        enableGlobalFilter={false}
                                                        enableHiding={false}
                                                        initialState={{
                                                            density: "compact"
                                                        }}
                                                        layoutMode="grid"
                                                        localization={MRT_Localization_IT}
                                                        muiTableBodyCellProps={{ sx: { borderRight: '1px solid #eee' } }}
                                                        muiExpandButtonProps={({ row, table }) => ({
                                                            onClick: () => table.setExpanded({
                                                                [row.id]: !row.getIsExpanded()
                                                            })
                                                        })}
                                                        enablePagination={false}
                                                        renderDetailPanel={({ row }) => {
                                                            let indexCargo = row.index

                                                            let cargoRow = wagonRow.original.Cargo[indexCargo]

                                                            let dataGoods = getDataGood(cargoRow.Goods, indAttach, indexWagon, indexCargo);
                                                            let dataVehicles = getVehiclesData(row.Vehicles, indAttach, indexWagon, indexCargo);

                                                            return <Box>
                                                                <Fragment key={a.attach_name + "_wagon_" + indexWagon + "_cargo_" + indexCargo}>
                                                                    <>
                                                                        {dataGoods.length > 0 &&
                                                                            <MaterialReactTable
                                                                                columns={getGoodsColumns()}
                                                                                data={dataGoods}
                                                                                enableColumnFilters={false}
                                                                                enableColumnResizing
                                                                                enableDensityToggle={false}
                                                                                enableExpandAll={false}
                                                                                enableExpanding={false}
                                                                                enableFullScreenToggle={false}
                                                                                enableGlobalFilter={false}
                                                                                enableHiding={false}
                                                                                initialState={{
                                                                                    density: "compact",
                                                                                }}
                                                                                layoutMode="grid"
                                                                                localization={MRT_Localization_IT}
                                                                                muiTableBodyCellProps={{ sx: { borderRight: '1px solid #eee' } }}
                                                                                muiExpandButtonProps={({ row, table }) => ({
                                                                                    onClick: () => table.setExpanded({
                                                                                        [row.id]: !row.getIsExpanded()
                                                                                    })
                                                                                })}
                                                                                enablePagination={false}
                                                                                renderDetailPanel={({ row }) => {
                                                                                    let indexGood = row.index
                                                                                    let ridList = cargoRow.Goods[indexGood].RID;

                                                                                    let ridData = getRIDData(ridList, indAttach, indexWagon, indexCargo, indexGood);

                                                                                    return ridData.length > 0 && <Box>
                                                                                        <MaterialReactTable
                                                                                            columns={getRIDColumns()}
                                                                                            data={ridData}
                                                                                            enableColumnFilters={false}
                                                                                            enableColumnResizing
                                                                                            enableDensityToggle={false}
                                                                                            enableExpandAll={false}
                                                                                            enableExpanding={false}
                                                                                            enableFullScreenToggle={false}
                                                                                            enableGlobalFilter={false}
                                                                                            enableHiding={false}
                                                                                            initialState={{
                                                                                                density: "compact"
                                                                                            }}
                                                                                            layoutMode="grid"
                                                                                            localization={MRT_Localization_IT}
                                                                                            muiTableBodyCellProps={{ sx: { borderRight: '1px solid #eee' } }}
                                                                                            muiExpandButtonProps={({ row, table }) => ({
                                                                                                onClick: () => table.setExpanded({
                                                                                                    [row.id]: !row.getIsExpanded()
                                                                                                })
                                                                                            })}
                                                                                            enablePagination={false}
                                                                                            renderTopToolbarCustomActions={() => {
                                                                                                return <Typography variant="h5">RID</Typography>;
                                                                                            }}
                                                                                            enableRowActions
                                                                                            renderRowActions={({ row }) => (
                                                                                                <RIDDialog rid={row.original} updateRID={updateRID} />
                                                                                            )}
                                                                                        />
                                                                                    </Box>
                                                                                }}
                                                                                renderTopToolbarCustomActions={() => {
                                                                                    return <Typography variant="h5">Goods</Typography>;
                                                                                }}
                                                                            />
                                                                        }

                                                                        {dataVehicles.length > 0 &&
                                                                            <MaterialReactTable
                                                                                columns={getVehicleColumns()}
                                                                                data={dataVehicles}
                                                                                muiTablePaperProps={{
                                                                                    style: {
                                                                                        marginTop: '20px'
                                                                                    },
                                                                                }}
                                                                                initialState={{
                                                                                    density: "compact"
                                                                                }}
                                                                                enableColumnFilters={false}
                                                                                enableColumnResizing
                                                                                enableDensityToggle={false}
                                                                                enableExpandAll={false}
                                                                                enableExpanding={false}
                                                                                enableFullScreenToggle={false}
                                                                                enableGlobalFilter={false}
                                                                                enableHiding={false}
                                                                                layoutMode="grid"
                                                                                localization={MRT_Localization_IT}
                                                                                muiTableBodyCellProps={{ sx: { borderRight: '1px solid #eee' } }}
                                                                                muiExpandButtonProps={({ row, table }) => ({
                                                                                    onClick: () => table.setExpanded({
                                                                                        [row.id]: !row.getIsExpanded()
                                                                                    })
                                                                                })}
                                                                                enablePagination={false}
                                                                                renderTopToolbarCustomActions={() => {
                                                                                    return <Typography variant="h5">Vehicles</Typography>;
                                                                                }}
                                                                            />
                                                                        }
                                                                    </>
                                                                </Fragment>
                                                            </Box>
                                                        }}
                                                        renderTopToolbarCustomActions={() => {
                                                            return <Typography variant="h5">Cargo</Typography>;
                                                        }}
                                                    />
                                                </>

                                            </Fragment>
                                        </Box>
                                    }
                                    else {
                                        return dataNoUTI.map(row => {
                                            let indexCargo = row.indCargo;
                                            let cargoRow = wagonRow.original.Cargo[indexCargo]

                                            let dataGoods = getDataGood(cargoRow.Goods, indAttach, indexWagon, indexCargo);
                                            let dataVehicles = getVehiclesData(row.Vehicles, indAttach, indexWagon, indexCargo);


                                            return <Box>
                                                <Fragment key={a.attach_name + "_wagon_" + indexWagon + "_cargo_" + indexCargo}>
                                                    <>
                                                        {dataGoods.length > 0 &&
                                                            <MaterialReactTable
                                                                columns={getGoodsColumns()}
                                                                data={dataGoods}
                                                                enableColumnFilters={false}
                                                                enableColumnResizing
                                                                enableDensityToggle={false}
                                                                enableExpandAll={false}
                                                                enableExpanding={false}
                                                                enableFullScreenToggle={false}
                                                                enableGlobalFilter={false}
                                                                enableHiding={false}
                                                                initialState={{
                                                                    density: "compact",
                                                                }}
                                                                layoutMode="grid"
                                                                localization={MRT_Localization_IT}
                                                                muiTableBodyCellProps={{ sx: { borderRight: '1px solid #eee' } }}
                                                                muiExpandButtonProps={({ row, table }) => ({
                                                                    onClick: () => table.setExpanded({
                                                                        [row.id]: !row.getIsExpanded()
                                                                    })
                                                                })}
                                                                enablePagination={false}
                                                                renderDetailPanel={({ row }) => {
                                                                    let indexGood = row.index
                                                                    let ridList = cargoRow.Goods[indexGood].RID;

                                                                    let ridData = getRIDData(ridList, indAttach, indexWagon, indexCargo, indexGood);

                                                                    return ridData.length > 0 && <Box>
                                                                        <MaterialReactTable
                                                                            columns={getRIDColumns()}
                                                                            data={ridData}
                                                                            enableColumnFilters={false}
                                                                            enableColumnResizing
                                                                            enableDensityToggle={false}
                                                                            enableExpandAll={false}
                                                                            enableExpanding={false}
                                                                            enableFullScreenToggle={false}
                                                                            enableGlobalFilter={false}
                                                                            enableHiding={false}
                                                                            initialState={{
                                                                                density: "compact"
                                                                            }}
                                                                            layoutMode="grid"
                                                                            localization={MRT_Localization_IT}
                                                                            muiTableBodyCellProps={{ sx: { borderRight: '1px solid #eee' } }}
                                                                            muiExpandButtonProps={({ row, table }) => ({
                                                                                onClick: () => table.setExpanded({
                                                                                    [row.id]: !row.getIsExpanded()
                                                                                })
                                                                            })}
                                                                            enableRowActions
                                                                            renderRowActions={({ row }) => (
                                                                                <RIDDialog rid={row.original} updateRID={updateRID} />
                                                                            )}
                                                                            enablePagination={false}
                                                                            renderTopToolbarCustomActions={() => {
                                                                                return <Typography variant="h5">RID</Typography>;
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                }}
                                                                renderTopToolbarCustomActions={() => {
                                                                    return <Typography variant="h5">Goods</Typography>;
                                                                }}
                                                            />
                                                        }

                                                        {dataVehicles.length > 0 &&
                                                            <MaterialReactTable
                                                                columns={getVehicleColumns()}
                                                                data={dataVehicles}
                                                                initialState={{
                                                                    density: "compact"
                                                                }}
                                                                muiTablePaperProps={{
                                                                    style: {
                                                                        marginTop: '20px'
                                                                    },
                                                                }}
                                                                enableColumnFilters={false}
                                                                enableColumnResizing
                                                                enableDensityToggle={false}
                                                                enableExpandAll={false}
                                                                enableExpanding={false}
                                                                enableFullScreenToggle={false}
                                                                enableGlobalFilter={false}
                                                                enableHiding={false}
                                                                layoutMode="grid"
                                                                localization={MRT_Localization_IT}
                                                                muiTableBodyCellProps={{ sx: { borderRight: '1px solid #eee' } }}
                                                                muiExpandButtonProps={({ row, table }) => ({
                                                                    onClick: () => table.setExpanded({
                                                                        [row.id]: !row.getIsExpanded()
                                                                    })
                                                                })}
                                                                enablePagination={false}
                                                                renderTopToolbarCustomActions={() => {
                                                                    return <Typography variant="h5">Vehicles</Typography>;
                                                                }}
                                                            />
                                                        }
                                                    </>
                                                </Fragment>
                                            </Box>
                                        });
                                    }

                                }}
                                renderTopToolbarCustomActions={() => {
                                    return <Typography variant="h5">Wagons</Typography>;
                                }}
                                enableRowActions
                                renderRowActionMenuItems={({ row, closeMenu }) => (
                                    <div>
                                        <Button
                                            variant="text"
                                            color="error"
                                            startIcon={<RemoveOutlined />}
                                            onClick={() => { closeMenu(); removeWagon(row.original); }}
                                        >
                                            Scarta
                                        </Button>
                                    </div>
                                )}
                            />
                        </AccordionDetails>
                    </Accordion>
                }
            }
            )
            }
        </Box >)
    }
    else {
        return <Box sx={{ m: 1 }}>
            {wagonStatus === 'Dati Distinta Carri non disponibili.' ?
                <>
                    <Box sx={{ textAlign: "right" }}>
                        <Tooltip title={t("tooltips.rielaboraDistinta")}>
                            <Button color="info" variant="contained" startIcon={<RefreshOutlined />} sx={{ mb: 2, mt: 2, mr: 1 }} onClick={restartIADetail}>Rielabora DISTINTA</Button>
                        </Tooltip>
                    </Box>
                    <Box sx={{ mt: 10, fontSize: 20, color: "black" }}>{wagonStatus}</Box>
                </>
                :
                <Snackbar sx={{ mt: 20 }} open={true} anchorOrigin={{ horizontal: "center", vertical: "top" }}>
                    <Box width={400} sx={{ textAlign: "center" }}><LinearProgress />
                        <Box sx={{ mt: 10, fontSize: 20, color: "black" }}>{wagonStatus}</Box></Box>
                </Snackbar>}
        </Box>
    }
}

export default ExtractionWagonsPage;