import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { PersonOutlineOutlined } from '@mui/icons-material';
import { Snackbar } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { accessTokenAtom, userAccountAtom, userAtom } from '../../states/wizardState';

export default function ProfileMenu() {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [user, setUser] = useRecoilState(userAtom);

  // eslint-disable-next-line
  const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);

  // eslint-disable-next-line
  const [userAccount, setUserAccount] = useRecoilState(userAccountAtom);

  const [snackbarStatus, setSnackBarStatus] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarStatus(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarStatus}
        autoHideDuration={3000}
        key={"modifySuccess"}
        onClose={handleCloseSnackBar}
        message={snackbarMessage}
      />

      <div className='profile'>
        <Button
          aria-controls={open ? 'basic-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          className='button-style'
          id="basic-button"
          onClick={handleClick}
        >
          <PersonOutlineOutlined />&nbsp;{user}
        </Button>
        <Menu
          anchorEl={anchorEl}
          id="basic-menu"
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          onClose={handleClose}
          open={open}
        >
          <MenuItem onClick={async () => {
            handleClose();

            if (isAuthenticated) {
              instance.logoutPopup({ account: userAccount })
                .then(res => { setUser(); setUserAccount(); setAccessToken(); })
                .catch(e => {
                  //APERTURA SNACKBAR
                  if (!snackbarStatus) setSnackBarStatus(true)
                  setSnackBarMessage("Errore durante l'operazione di logout!")
                });
            }
            else {
              try {
                setUser();
                setUserAccount();
                setAccessToken();
                navigate("/");
              }
              catch (e) {
                //APERTURA SNACKBAR
                if (!snackbarStatus) setSnackBarStatus(true)
                setSnackBarMessage("Errore durante l'operazione di logout!")
              }
            }
          }}>
            Logout
          </MenuItem>
        </Menu>
      </div >
    </>
  );
}