import { Backdrop, Box, CircularProgress } from "@mui/material"

export default function BackdropLoading(props) {
    return <Backdrop
        open={true}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
        <CircularProgress color="inherit" />
        <Box sx={{ ml: 5, fontWeight: "bold" }}>{props.message}</Box>
    </Backdrop>
}