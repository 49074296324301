import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React from "react";
import { useWizard } from "react-use-wizard";
import { useRecoilState, useRecoilValue } from "recoil";

import { goToSend, setOperatorMail, startProcessEmail } from '../../client/apiClient';
import { checkRIDAtom, selectedMailAtom, wizardPageAtom } from "../../states/wizardState";

export default function Footer() {
  const { previousStep, nextStep, isFirstStep, isLastStep, activeStep, goToStep } = useWizard();

  const pageState = (wizardPageAtom);
  const selectedMail = useRecoilValue(selectedMailAtom);

  const [checkRID, setCheckRID] = useRecoilState(checkRIDAtom);

  React.useEffect(() => {
    if (pageState.id !== undefined && pageState.id !== activeStep) {
      goToStep(pageState.id);
    }
  }, [activeStep, goToStep, pageState]);

  const isNextEnabled = () => {
    if (isLastStep) {
      return false;
    }
    else {
      if (selectedMail && selectedMail.id && activeStep <= selectedMail.current_step)
        return true;
      return false;
    }
  }

  const next = async () => {
    try {
        if (selectedMail.id_process !== "" && selectedMail.available) {
          await setOperatorMail(selectedMail.id_mail);
        }
        else if (selectedMail.current_step === 0) {
          if (!selectedMail.already_started)
            await startProcessEmail(selectedMail.id);
        }
        // check dei rid da convalidare, gestisco il next dentro il componente stesso
        else if (activeStep === 2) {
          setCheckRID(checkRID+1);
          return;
        }
        else if (activeStep === 4) {
          await goToSend(selectedMail.id_mail);
        }

        nextStep();
    }
    catch (e) {
      console.error(e);
    }
  }

  return <Grid container className='footer'>
    <Grid item xs={6}>
      <Button disabled={isFirstStep ? true : false} onClick={() => previousStep()} color="inherit" variant="outlined" startIcon={<ArrowBackOutlined />}>
        Indietro
      </Button>
    </Grid>
    <Grid item xs={6}>
      <Button disabled={isNextEnabled() ? false : true} onClick={next} color="primary" variant="contained" endIcon={<ArrowForwardOutlined />}>
        Avanti
      </Button>
    </Grid>
  </Grid>
}