import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ConfirmProvider } from "material-ui-confirm";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import App from './App';
import { Config } from "./configApp";

import './i18n';

import RecoilNexus from 'recoil-nexus';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

fetch("./config.json", { method: 'GET' })
  .then(response => response.json())
  .then(jconfig => {
    if (window.location.protocol === "https:") {
      jconfig.API_BASE_PATH = jconfig.API_BASE_PATH.replace("http:", "https:");
      jconfig.SSE_URL.replace("http:", "https:");
    }
    Config.serviceUrls.API_BASE_PATH = jconfig.API_BASE_PATH;
    Config.serviceUrls.SSE_URL = jconfig.SSE_URL;
    Config.serviceUrls.ACK_INTERVAL = jconfig.ACK_INTERVAL;
    Config.selectedAAD = jconfig.selectedAAD;
    Config.enableCredentialLogin = jconfig.enableCredentialLogin;

    const pca = new PublicClientApplication({
      auth: Config.AAD[Config.selectedAAD]
    });

    root.render(
      <MsalProvider instance={pca}>
        <RecoilRoot>
            <RecoilNexus/>
            <ConfirmProvider>
              <App />
            </ConfirmProvider>
        </RecoilRoot>
      </MsalProvider>
    );
  })