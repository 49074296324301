import { CheckBox, RefreshOutlined } from "@mui/icons-material";
import { Alert, Box, Button, Checkbox, FormControlLabel, Grid, LinearProgress, Radio, RadioGroup, Snackbar, Tooltip, Typography } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_IT } from "material-react-table/locales/it";
import { useConfirm } from "material-ui-confirm";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useWizard } from "react-use-wizard";
import { useRecoilState, useRecoilValue } from "recoil";

import { getProcessMail, selectRds, startProcessRds } from "../../client/apiClient";
import { selectedMailAtom, sse } from "../../states/wizardState";
import { NumberInput } from "./NumberInput";

const AssociationPage = () => {
    const sseClient = useRecoilValue(sse);
    const [selectedMail, setSelectedMail] = useRecoilState(selectedMailAtom);

    const [selectedRds, setSelectedRds] = useState();
    const [ragionamento, setRagionamento] = useState("");
    const [rdsStatus, setRdsStatus] = useState("");
    const [rdsFields, setRdsField] = useState([]);

    const [snackbarStatus, setSnackBarStatus] = useState(false);
    const [snackbarMessage, setSnackBarMessage] = useState("");

    const [fromDay, setFromDay] = useState(0);
    const [toDay, setToDay] = useState(3);

    const { t } = useTranslation();

    const {
        activeStep
    } = useWizard();

    const confirm = useConfirm();

    useEffect(() => {
        if (selectedMail) {
            getMailDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMailDetail = () => {
        setRdsStatus(t(`rds.dataLoading`));

        getProcessMail(selectedMail.id_mail, activeStep).then(res => {
            if (res.data) {
                //refresh dello stato
                let sm = { ...selectedMail };
                sm.current_step = res.data.current_step;
                if (res.data.id_process)
                    sm.id_process = res.data.id_process;
                sm.available = false;
                setSelectedMail(sm);

                setRagionamento(res.data.inference_rds);
            }

            if (res.data.content_rds == null)
                res.data.content_rds = "[]";

            if (res.data && res.data.status_elaboration && res.data.status_elaboration.in_elaboration && res.data.status_elaboration.type_elaboration === "rds") {
                setRdsStatus(res.data.status_elaboration.msg);
            }

            else if (res.data && res.data.content_rds) { // && res.data.content_rds.length > 0) {
                setRdsField(res.data.content_rds);

                setRdsStatus("OK");
            }
            else
                setRdsStatus(t(`rds.dataNotAvailable`));
        }).catch(() => { /*ANNULLA*/ });
    }

    const restartRds = () => {
        confirm({
            cancellationText: t(`shared.modals.cancel`),
            cancellationButtonProps: { color: "inherit", variant: "outlined" },
            confirmationText: "Procedi",
            confirmationButtonProps: { color: "primary", variant: "contained" },
            description: <> Avviare il ricalcolo della richiesta di servizio? <br /><br /> Attenzione, tutti i dati presenti verranno cancellati. </>,
            title: "Ricalcola richiesta di servizio"
        })
            .then(() => {
                setRdsStatus("Ricalcolo richiesta di servizio in corso...");

                startProcessRds(selectedMail.id_mail, fromDay, toDay)
                    .then(res => {
                        if (res && res.data) {
                            //APERTURA SNACKBAR
                            if (!snackbarStatus) setSnackBarStatus(true)
                            setSnackBarMessage(res.data.message)
                        }
                    })
                    .catch(() => { /*ANNULLA*/ })
                //.finally(() => refresh());
            })
            .catch(() => { /*ANNULLA*/ });
    }

    const handleSelectionRds = (row) => {

        let rds = row.original;

        setSelectedRds(row.id);

        // se non array seleziono rds diversamente mostro popup di scelta cliente
        if (!rds.cliente_titolare || !rds.cliente_titolare.includes(",")) {
            confirm({
                title: 'Selezione RDS',
                description: 'Confermi la selezione effettuata?',
                confirmationText: 'Conferma',
                cancellationText: 'Annulla',
            }).then(() => {
                setRds(row);
            }).catch(e => { setSelectedRds() });
        }

        else {
            let clienteTitolare = rds.cliente_titolare.split(",");
            let descrizioneClienteTitolare = rds.descrizione_cliente_titolare.split(",");

            let selectedCliente;

            confirm({
                title: 'Seleziona un cliente per la selezione RDS',
                description: 'Scegli tra le seguenti opzioni e conferma',
                content: (
                    <RadioGroup onChange={(e) => selectedCliente = e.target.value}>
                        {clienteTitolare.map((c, index) => <FormControlLabel value={index} control={<Radio />}
                            label={c.trim() + " " + descrizioneClienteTitolare[index]} 
                         />)}
                    </RadioGroup>
                ),
                confirmationText: 'Conferma',
                cancellationText: 'Annulla',
            }).then(() => {
                rds.cliente_titolare = clienteTitolare[selectedCliente].trim();
                rds.descrizione_cliente_titolare = descrizioneClienteTitolare[selectedCliente].trim();
                setRds(row);

            }).catch(e => { setSelectedRds()});
        }
    };

    const setRds = (row) => {

        let modifiedData = [...rdsFields];

        modifiedData.forEach(mr => { if (mr.SELECTED) mr.SELECTED = false });
        modifiedData[row.id].SELECTED = true;

        selectRds({ "id": selectedMail.id_process, "content_rds": modifiedData }).then(
            res => {
                if (res && res.data) {
                    setRdsField(modifiedData);

                    //APERTURA SNACKBAR
                    if (!snackbarStatus) setSnackBarStatus(true)
                    setSnackBarMessage("Modifica Effettuata")
                }
            }
        ).catch(() => { /*ANNULLA*/ }).finally(() => refresh());
    }

    useEffect(() => {
        if (sseClient) {
            console.log("avvio run_rds_extractor listener");
            sseClient.addEventListener(
                'run_rds_extractor', runRdsExtractorListener)

            return () => {
                console.log("rimozione run_rds_extractor listener");
                sseClient.removeEventListener("run_rds_extractor", runRdsExtractorListener);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sseClient]);

    const runRdsExtractorListener = (event) => {
        if (event.data) {
            let data = JSON.parse(event.data);
            if (data.id === selectedMail.id && data.message.type_elaboration === 'rds') {
                if (data.message.in_elaboration === false || (data.message.in_elaboration === true && data.message.perc_avz >= 100)) {
                    refresh();
                } else {
                    setRdsStatus(data.message.msg);
                }
            }
        }
    }

    const refresh = () => {
        setRdsStatus("");
        setRdsField([]);
        getMailDetail();
    }

    const rdsColumns = useMemo(
        () => {
            let fieldList = [
                "cliente_titolare",
                "contratto",
                "datatime_fine_rc",
                "datatime_inizio_rc",
                "datatime_rds",
                "des_if_stazione_di_contatto",
                "des_impianto_dest_effett",
                "des_raccordo_destinatario",
                "des_raccordo_mittente",
                "des_stazione_destinataria",
                "des_stazione_mittente",
                "descrizione_cliente_titolare",
                "desimpianto_mitt_effett",
                //"id",
                "id_rc",
                "id_rds",
                "if_stazione_di_contatto",
                "impianto_dest_effett",
                "impianto_mitt_effett",
                "is_data_rds",
                "lunghezza_max_ammessa",
                "peso_max_ammesso",
                "raccordo_destinatario",
                "raccordo_mittente",
                "rate_score",
                "rete_dest_effett",
                "rete_destinataria",
                "rete_mitt_effett",
                "rete_mittente",
                "rid_si_no",
                "sequenza_delle_if",
                "sequenza_ordinata_pdf",
                "source_type",
                "stato_rds",
                "stazione_destinataria",
                "stazione_mittente",
                "tipo_prodotto"
            ]


            let objList = [];
            fieldList.forEach(field => {
                objList.push(
                    {
                        accessorKey: field,
                        header: t(`rds.table.${field}`),
                        size: 250,
                        Cell: ({ renderedCellValue }) => {
                            return <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word' }}>{renderedCellValue}</Box>
                        }
                    }
                )
            })

            return objList
        },
        [t]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarStatus(false);
    };

    const tableRds = useMaterialReactTable({
        enableRowActions: true,
        positionActionsColumn: "first",
        muiTableBodyCellProps: { sx: { borderRight: '1px solid #eee' } },
        renderRowActions: ({ row }) => {
            return (
                <div>
                    {row.original.SELECTED ? <CheckBox sx={{color:"green", p:1}}/> :
                        <Checkbox checked={selectedRds===row.id} onChange={() => { handleSelectionRds(row) }} />
                    }
                </div>
            )
        },
        initialState: {
            density: "compact",
            columnVisibility: {
                if_stazione_di_contatto: false,
                impianto_dest_effett: false,
                impianto_mitt_effett: false,
                is_data_rds: false,
                lunghezza_max_ammessa: false,
                peso_max_ammesso: false,
                raccordo_destinatario: false,
                raccordo_mittente: false,
                rate_score: false,
                rete_dest_effett: false,
                rete_destinataria: false,
                rete_mitt_effett: false,
                rete_mittente: false,
                rid_si_no: false,
                sequenza_delle_if: false,
                sequenza_ordinata_pdf: false,
                source_type: false,
                stato_rds: false,
                stazione_destinataria: false,
                stazione_mittente: false,
                tipo_prodotto: false,
            },
            columnPinning: {
                left: ['mrt-row-actions'],
            },
        },
        localization: {
            ...MRT_Localization_IT,
            actions: 'Selezione'
        },
        columns: rdsColumns,
        data: rdsFields,
        enableColumnResizing: true,
        layoutMode: "grid",
        enablePagination: false,
        muiTablePaperProps: {
            elevation: 0,
            sx: {
                // height: '80vh',
                overflow: 'auto'
            },
        }
    });

    if (rdsStatus === "OK") {
        return (<Box sx={{ m: 1 }}>
            <Grid sx={{ mt: 1, justifyContent: "end", textAlign: "center" }} container spacing={2}>
                <Grid item>
                    <span>Giorno Da</span>
                    <NumberInput key={"from"} min={0} value={fromDay} onChange={(event, newValue) => setFromDay(!newValue ? 0 : newValue)}></NumberInput>
                </Grid>
                <Grid item>
                    <span>Giorno a</span>
                    <NumberInput sx={{ ml: 1 }} min={0} key={"to"} value={toDay} onChange={(event, newValue) => setToDay(!newValue ? 0 : newValue)}></NumberInput>
                </Grid>
                <Tooltip title={t("tooltips.ricalcolaRichiestaDiServizio")}>
                    <Button color="info" variant="contained" startIcon={<RefreshOutlined />} sx={{ mb: 2, mt: 4.5, mr: 1, ml: 1 }} onClick={restartRds}>Ricalcola RdS</Button>
                </Tooltip>
            </Grid>

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarStatus}
                autoHideDuration={3000}
                key={"modifySuccess"}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {ragionamento && ragionamento !== "" &&
                <Alert severity="info">
                    <Typography paragraph={true}>
                        {ragionamento}
                    </Typography>
                </Alert>
            }

            <Alert severity="info">
                <Typography sx={{ whiteSpace: "pre-wrap" }} paragraph={true}>
                    {t("rds.criteriRds")}
                </Typography>
            </Alert>

            <MaterialReactTable table={tableRds} />
        </Box>);
    }
    else {
        return <Snackbar sx={{ mt: 20 }} open={true} anchorOrigin={{ horizontal: "center", vertical: "top" }}>
            <Box width={400} sx={{ textAlign: "center" }}>
                {rdsStatus === t(`rds.dataNotAvailable`) ? <></> : <LinearProgress />}

                <Box sx={{ mt: 10, fontSize: 20, color: "black" }}>{rdsStatus}</Box></Box>
        </Snackbar>

    }
}
export default AssociationPage;