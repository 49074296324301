import { ExpandMore, SendOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Tooltip } from "@mui/material";
import { useState } from "react";
import XMLViewer from 'react-xml-viewer';
import { useRecoilState, useRecoilValue } from "recoil";

import { t } from "i18next";
import { sendEcn } from "../../client/apiClient";
import { selectedMailAtom, wizardPageAtom } from "../../states/wizardState";
import BackdropLoading from "../utils/BackdropLoading";

const SendEcnPage = () => {
    const selectedMail = useRecoilValue(selectedMailAtom);

    // eslint-disable-next-line
    const [wizardPage, setWizardPage] = useRecoilState(wizardPageAtom);

    const [snackbarStatus, setSnackBarStatus] = useState(false);
    const [snackbarMessage, setSnackBarMessage] = useState("");

    const [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [xml, setXml] = useState();
    const [xmlError, setXmlError] = useState();

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const send = async () => {
        try {
            setLoading(true);
            let res = await sendEcn(selectedMail.id_mail);
            if (res && res.data && res.data.requestXML) {
                openDialog();
                let xml = res.data.responseSoap.data;

                setXml(xml);

                if (xml.includes("<SAP:Error")) {
                    setXmlError(xml.substring(xml.indexOf("<SAP:Error"), xml.indexOf("</SAP:Error>") + 12));
                }
                else if (xml.includes("<ZBAPIRET2>") && !xml.includes("<TYPE>I</TYPE>")) {
                    setXmlError(xml.substring(xml.indexOf("<ZBAPIRET2>"), xml.indexOf("</ZBAPIRET2>") + 12));
                } else {
                    setXmlError();
                }

            } else {
                //APERTURA SNACKBAR
                if (!snackbarStatus) setSnackBarStatus(true)
                setSnackBarMessage("Errore durante l'invio ECN!")
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarStatus(false);
    };

    return loading ? <BackdropLoading message="Invio dati..." /> : (
        <>
            <Box sx={{ m: 1 }}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackbarStatus}
                    autoHideDuration={3000}
                    key={"modifySuccess"}
                    onClose={handleClose}
                    message={snackbarMessage}
                />
                <Dialog
                    fullScreen={false}
                    maxWidth={"xl"}
                    open={open}
                    onClose={closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" sx={{ color: "white", background: xmlError ? "red" : "green" }}>
                        {"Invio ECN " + (xmlError ? "-> ERRORE" : "OK")}
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ height: "70vh", m: 1 }}>
                            {xmlError &&
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                    >
                                        Tag XML di Errore
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <XMLViewer xml={xmlError} />

                                    </AccordionDetails>
                                </Accordion>}

                            <Accordion>
                                <AccordionSummary
                                    defaultExpanded={xmlError ? false : true}
                                    expandIcon={<ExpandMore />}
                                >
                                    XML di Risposta
                                </AccordionSummary>
                                <AccordionDetails>
                                    <XMLViewer xml={xml} />

                                </AccordionDetails>
                            </Accordion>

                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { closeDialog(); /*setWizardPage({ id: 0, name: "Scelta Range Date" })*/ }}>OK</Button>
                    </DialogActions>
                </Dialog>


                <Box sx={{ textAlign: "right" }}>
                    <Tooltip title={t("tooltips.invioEcn")}>
                        <Button color="info" variant="contained" startIcon={<SendOutlined />} sx={{ mb: 2, mt: 2, mr: 1 }} onClick={send}>Invio ECN</Button>
                    </Tooltip>
                </Box>
            </Box>
        </>
    )
}

export default SendEcnPage;