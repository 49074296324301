import { CloseOutlined, Error, ExpandMoreOutlined, GetApp, Warning } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Chip, DialogContent, Grid, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { saveAs } from 'file-saver';
import * as React from 'react';

import { attachDownload, emailDetail,emailPreview } from '../../client/apiClient';
import BackdropLoading from '../utils/BackdropLoading';

export default function MailInfoDialog(props) {
  const { onClose, open, mailId } = props;

  const [details, setDetails] = React.useState();

  // eslint-disable-next-line
  const [inputAttach, setInputAttach] = React.useState([]);
  const attachsState=[]
  const [loadAttach , setLoadAttach ] = React.useState([]);
  
  const [openTotalPageError, setOpenTotalPageError] = React.useState(false);

  const saveFile = (attach) => {
    if(document.getElementById("spin-"+attach.original_name)!=null)
      document.getElementById("spin-"+attach.original_name).style.display="";

    attachDownload(mailId, encodeURIComponent(attach.original_name)).then(res => {
      if(document.getElementById("spin-"+attach.original_name)!=null)
        document.getElementById("spin-"+attach.original_name).style.display="none";

      saveAs(new Blob([res.data], { type: "application/pdf" }), attach.original_name);
    }).catch(() => { 
      if(document.getElementById("spin-"+attach.original_name)!=null)
        document.getElementById("spin-"+attach.original_name).style.display="none"; 
      alert("Errore in download!")/*ANNULLA*/ });
  };

  React.useEffect(() => {
    setDetails();
    
    setInputAttach([]);
    if (mailId) {
      emailDetail(mailId)
        .then(res => {
          setDetails(res.data);
          if (res.data.attachs_preview) {
            let ia = [];
            res.data.attachs_preview.map(a => ia.push({ name: a.original_name }));
            setInputAttach(ia);
            
          }
        }).catch(() => {/*ANNULLA*/ });
    }
  }, [mailId])

  const handleClose = () => {
    onClose();
  };

  const tryToLoadPreview =(id_mail,attach) =>{
    if(attach.preview_base64===undefined || attach.preview_base64 ==null || attach.preview_base64 =="")
    {
      attachsState.push(attach.original_name);
      setLoadAttach(attachsState);

      emailPreview(id_mail,attach.original_name)
        .then(res => {
          attach.preview_base64=res.data.preview;
          attachsState.pop(attach.original_name);
          setLoadAttach([...attachsState]);
        }).catch(() => { attachsState.pop(attach.original_name); setLoadAttach([...attachsState]);});
    }
  }

  return (

    (!details) ?
      mailId && <BackdropLoading message="Caricamento dati..." />
      :
      <Dialog maxWidth="lg" onClose={handleClose} open={open}>
        <DialogTitle>Dettaglio</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined />
        </IconButton>

        <DialogContent dividers>

          <Snackbar open={openTotalPageError} anchorOrigin={{ horizontal: "center", vertical: "bottom" }} onClose={() => setOpenTotalPageError(!openTotalPageError)}>
            <Alert
              severity="error"
              variant="filled"
              sx={{ width: '100%' }}
            >
              Valore troppo elevato.
            </Alert>
          </Snackbar>

          <TableContainer sx={{ marginBottom: "10px" }} component={Paper}>
            <Table size='small' style={{ borderColor: "lightgray" }} border={1}>
              <TableBody>
                <TableRow>
                  <TableCell className='listLabel' component="th" scope="row">
                    DA
                  </TableCell>
                  <TableCell>{details.from_address + " - " + details.from_domain}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className='listLabel' component="th" scope="row">
                    A
                  </TableCell>
                  <TableCell>{details.to_address + " - " + details.to_domain}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className='listLabel' component="th" scope="row">
                    DATA
                  </TableCell>
                  <TableCell>{details.sent}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className='listLabel' component="th" scope="row">
                    OGGETTO
                  </TableCell>
                  <TableCell>{details.subj}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className='listLabel' component="th" scope="row">
                    CORPO
                  </TableCell>
                  <TableCell>{details.body}</TableCell>
                </TableRow>
                {details.cc &&
                  <TableRow>
                    <TableCell className='listLabel' component="th" scope="row">
                      CC
                    </TableCell>
                    <TableCell>{details.cc.map(item => item.address).join()}</TableCell>
                  </TableRow>
                }
                {details.bcc &&
                  <TableRow>
                    <TableCell className='listLabel' component="th" scope="row">
                      BCC
                    </TableCell>
                    <TableCell>{details.bcc.map(item => item.address).join()}</TableCell>
                  </TableRow>
                }
                <TableRow>
                  <TableCell className='listLabel' component="th" scope="row">
                    LAVORAZIONE PRECEDENTE
                  </TableCell>
                  <TableCell>{details.already_started ? "SI" : "NO"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className='listLabel' component="th" scope="row">
                    WARNING
                  </TableCell>
                  <TableCell>{details.has_warning ? "SI" : "NO"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className='listLabel' component="th" scope="row">
                    COMPLETATO
                  </TableCell>
                  <TableCell>{details.completed ? "SI" : "NO"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {details.attachs_preview.map((attach, i) =>
            <>
              {attach.status_cim.in_error &&
                <Alert icon={<Error style={{ padding: "0 0 7px 7px" }} />} sx={{ mt: 1, padding: "0", cursor: "pointer", width: "100%" }} variant="filled" severity="error">
                  {attach.status_cim.msg_error}
                </Alert>
              }
              {attach.status_detail.in_error &&
                <Alert icon={<Error style={{ padding: "0 0 7px 7px" }} />} sx={{ mt: 1, padding: "0", cursor: "pointer", width: "100%" }} variant="filled" severity="error">
                  {attach.status_detail.msg_error}
                </Alert>
              }
              <Accordion onChange={(e,expanded) => {if(expanded){ tryToLoadPreview(mailId,attach)}}} className='accordionMail' key={"allegato" + i}>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                >
                  <Grid container justify="flex-end">
                    <Grid item xs={3}>
                      <Typography style={{ lineHeight: "2", fontWeight: "bold" }}>{"Anteprima allegato: " + attach.original_name}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      {attach.is_cim &&
                        <Chip sx={{ mr: 1 }} label={"CIM (Pag. " + attach.page_cim + " )"} color="success" variant="outlined" />
                      }
                      {attach.contains_detail &&
                        <Chip label={"DISTINTA (Pag. " + attach.pages_detail + " )"} color="success" variant="outlined" />
                      }
                      {attach.contains_waste &&
                        <Chip label={"RIFIUTI (Pag. " + attach.pages_waste + " )"} color="warning" variant="outlined" />
                      }
                      {attach.contains_single_wagon &&
                        <Chip label={"CARRO SINGOLO"} color="info" variant="outlined" />
                      }
                      {!attach.contains_detail && !attach.is_cim && !attach.contains_waste && !attach.contains_single_wagon &&
                        <Chip label="NON IDENTIFICATO" color="warning" variant="outlined" />
                      }
                    </Grid>
                    <Grid item xs={2}>
                      <CircularProgress style={{display:"none"}} id={"spin-"+attach.original_name}></CircularProgress>
                      <Button color='primary' startIcon={<GetApp />} variant="contained" onClick={(event) => { event.stopPropagation(); saveFile(attach) }}>SCARICA</Button>
                    </Grid>
                  </Grid>

                </AccordionSummary>
                <AccordionDetails  >
                  {!(attachsState.includes(attach.original_name)) && (attach.preview_base64===undefined || attach.preview_base64==null || attach.preview_base64=="") && 
                  <CircularProgress  id={"spinpreview-"+attach.original_name}></CircularProgress>}
                  
                  {(attach.preview_base64!==undefined && attach.preview_base64!=null && attach.preview_base64!="")  && 
                  <img alt="attachment" key={"attach_" + i} width={"100%"} height="auto" src={`${attach.preview_base64}`} />}
                </AccordionDetails>
              </Accordion>
            </>

          )
          }

          {details.has_warning &&
            /*<Accordion sx={{ boxShadow: 0 }}>
              <AccordionSummary>*/
            <Alert icon={<Warning style={{ padding: "0 0 7px 7px" }} />} sx={{ mt: 1, padding: "0", cursor: "pointer", width: "100%" }} variant="filled" severity="warning">
              Sono presenti degli warning sugli allegati.
            </Alert>
            /*</AccordionSummary>
            <AccordionDetails>
              {details.attachs_preview.map((attach, i) =>
                <Grid container>
                  <Grid xs={12} sx={{ margin: "auto" }} item>
                    <TextField disabled margin="dense" value={"Allegato: " + attach.original_name}></TextField>
                    <TextField
                      sx={{ ml: 1 }}
                      margin="dense"
                      variant="standard"
                      label="Pagina CIM"
                      type="number"
                      onChange={(e) => {
                        if (e.target.value > attach.tot_pages) {
                          setOpenTotalPageError(!openTotalPageError);
                        }
                        else {
                          let ia = [...inputAttach];
                          ia.find((a) => a.name === attach.original_name).page_cim = parseInt(e.target.value);
                          setInputAttach(ia);
                        }
                      }}
                    />
                    <TextField
                      sx={{ ml: 1 }}
                      margin="dense"
                      variant="standard"
                      label="Pagina Dettaglio"
                      type="number"
                      onChange={(e) => {
                        if (e.target.value > attach.tot_pages) {
                          setOpenTotalPageError(!openTotalPageError);
                        }
                        else {
                          let ia = [...inputAttach];
                          ia.find((a) => a.name === attach.original_name).page_detail = parseInt(e.target.value);
                          setInputAttach(ia);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              )
              }
              <Box sx={{ m: 1, textAlign: "right" }}><Button onClick={() => {
                adjustCimDetailAttach(details.id, inputAttach).then(res => { if (res) { handleClose() } })
              }} variant='contained'>Invia Correzione</Button></Box>
            </AccordionDetails>
          </Accordion>*/
          }

          {details.history_operators.length > 0 &&
            <Accordion sx={{ mt: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
              >
                <Typography style={{ lineHeight: "2", fontWeight: "bold" }}>Storico Operatori</Typography>
              </AccordionSummary>
              <AccordionDetails>

                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Username</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Nome</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Cognome</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Inizio</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Fine</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {details.history_operators.map((ope) => (
                        <TableRow
                          key={ope.surname}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {ope.username}
                          </TableCell>
                          <TableCell>{ope.name}</TableCell>
                          <TableCell>{ope.surname}</TableCell>
                          <TableCell>{ope.mail}</TableCell>
                          <TableCell>{new Date(ope.timestamp_start * 1000).toLocaleString()}</TableCell>
                          <TableCell>{new Date(ope.timestamp_end * 1000).toLocaleString()}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          }
        </DialogContent>
      </Dialog>
  );
}