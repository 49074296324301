import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useMsal } from "@azure/msal-react";
import Login, { Logo, Reset, Submit } from '@react-login-page/base';
import { Alert, Box, Button, Snackbar } from "@mui/material";

import { Config } from "../../configApp";
import LoginLogo from '../../logo.png';
import { accessTokenAtom, userAccountAtom, userAtom } from '../../states/wizardState';

const LoginPage = () => {
    const { instance } = useMsal();

    const setUser = useSetRecoilState(userAtom);
    const setAccessToken = useSetRecoilState(accessTokenAtom);
    const setUserAccount = useSetRecoilState(userAccountAtom);

    const [snackbarStatus, setSnackBarStatus] = useState(false);
    const [snackbarMessage, setSnackBarMessage] = useState("");

    const navigate = useNavigate();

    const handleAADLogin = () => {
        instance.loginPopup({
            scopes: [ "User.Read"],
        }).then(res => {
            setAccessToken("Bearer " + res.accessToken);
            setUser(res.account.name);
            setUserAccount(res.account);
        }).catch(e => {
            //APERTURA SNACKBAR
            if (!snackbarStatus) setSnackBarStatus(true)
            setSnackBarMessage("Errore durante l'operazione di login!")
            console.error(e);
        });
    };

    const handleLogin = (even) => {
        even.preventDefault();
        const formData = new FormData(even.target);
        const data = Object.fromEntries(formData);

        if (!data.username || data.username === "" || !data.password || data.password === "") {
            //APERTURA SNACKBAR
            if (!snackbarStatus) setSnackBarStatus(true)
            setSnackBarMessage("Inserire Username/Password validi!")
            return;
        }

        setUser(data.username);
        setAccessToken();
        navigate("/");
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarStatus(false);
    };

    return Config.enableCredentialLogin ? <form onSubmit={handleLogin}>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarStatus}
            autoHideDuration={3000}
            key={"loginError"}
            onClose={handleClose}
            message={snackbarMessage}
        >
            <Alert
                onClose={handleClose}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>

        <Login style={{ backgroundColor: "white", height: '100vh' }}>
            <Login.Title className="hidden-title">""</Login.Title>
            <Logo>
                <img alt="logo" width={300} src={LoginLogo} />
            </Logo>
            <Submit>Login</Submit>
            <Reset style={{ background: 'green' }} onClick={handleAADLogin}>Login AAD</Reset>
        </Login>
    </form>
        :
        <form onSubmit={(event) => { event.preventDefault(); handleAADLogin() }}>
            <Box sx={{ marginTop: '35vh', display: "grid", justifyContent: "center", alignItems: "center" }}>
                <Box><img alt="logo" width={300} src={LoginLogo} /></Box>
                <Box sx={{ mt: 4, textAlign: "center" }}><Button type="submit" sx={{ width: "200px" }} variant="contained">Login AAD</Button></Box>
            </Box>
        </form>
};

export default LoginPage;