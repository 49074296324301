import { CheckBoxRounded, CloseOutlined, RefreshOutlined, WarningOutlined } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, LinearProgress, Snackbar, Tooltip } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { MRT_Localization_IT } from 'material-react-table/locales/it';
import { useConfirm } from "material-ui-confirm";
import { PDFDocument } from 'pdf-lib';
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useWizard } from 'react-use-wizard';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getProcessMail, startProcessCI, startProcessIACim, startProcessOrfeus, updateProcess } from '../../client/apiClient';
import { selectedMailAtom, sse } from '../../states/wizardState';
import BackdropLoading from '../utils/BackdropLoading';
import EditFieldDialog from './editFieldDialog';

const ExtractionMailPage = () => {
    const [selectedMail, setSelectedMail] = useRecoilState(selectedMailAtom);
    const sseClient = useRecoilValue(sse);

    const [cimFields, setCimFields] = useState([]);
    const [cimStatus, setCimStatus] = useState("");

    const [modifiedCim, setModifiedCim] = useState();
    const [disabledFields, setDisabledFields] = useState([]);

    const [snackbarStatus, setSnackBarStatus] = useState(false);
    const [snackbarMessage, setSnackBarMessage] = useState("");

    const [msgLoading, setMsgLoading] = useState();

    const { t } = useTranslation();

    //CHECKBOXES
    const [codificaInternazionale, setCodificaInternazionale] = useState(false);
    const [orfeusFilter, setOrfeusFilter] = useState(false);

    const confirm = useConfirm();

    const {
        activeStep
    } = useWizard();

    const cimKey = useMemo(
        () => [
            {
                accessorKey: 'key',
                header: 'Attributo',
                Cell: ({ cell }) => {
                    return <Box sx={{ fontWeight: "bold", fontStyle: "italic", whiteSpace: "normal", wordWrap: 'break-word', }}>{
                        cell.getValue() ?
                            t("cim." + cell.getValue())
                            :
                            ""
                    }</Box>
                }
            },
            {
                accessorKey: 'extracted',
                header: 'Estrazione',
                grow: true,
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word' }}>{cell.getValue()}</Box>
                )
            },
            {
                accessorKey: 'orfeus',
                header: 'Orfeus',
                grow: true,
                Cell: ({ renderedCellValue, row }) => {
                    if (renderedCellValue)
                        return <Box sx={{ whiteSpace: "normal", paddingLeft: "2px", width: "100%", wordWrap: 'break-word', border: row.original.extracted !== renderedCellValue ? '2px solid orange' : "none" }}>
                            {(renderedCellValue instanceof Object) ? renderedCellValue.map(key => { return (key.text ? key.text : "") }) : renderedCellValue}
                        </Box>
                    return "";
                }
            },
            {
                accessorKey: 'normalized',
                header: 'Pulizia',
                grow: true,
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word' }}>{cell.getValue()}</Box>
                )
            },
            {
                accessorKey: 'modified',
                header: 'Correzione Effettuata',
                size: 100,
                Cell: ({ renderedCellValue, row }) => {
                    return <Box sx={{ color: 'green', width: "100%", textAlign: "center" }}>
                        {
                            Array.isArray(renderedCellValue) ?
                                (renderedCellValue.filter((v) => v.selected && v.selected === true).length > 0 ? <CheckBoxRounded color="info" /> : null)
                                : (renderedCellValue && renderedCellValue !== row.original.orfeus ? <CheckBoxRounded color="info" /> : null)
                        }
                    </Box>
                }
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],);

    const [cimPdf, setCimPdf] = useState();
    const [pdfDialogOpen, setPdfDialogOpen] = useState(false);

    let tableData = []

    if (codificaInternazionale) {
        tableData = cimFields.filter((f) => f.codificaInternazionale === true)
    }
    else if (orfeusFilter) {
        let fieldsToFilter = ["29_drafting_date", "10_station_code_deliverypoint", "62_station_ship_identification", "12_prod_station_code_deliverypoint", "62_consignment_number", "62_country_ship_identification", "62_company_ship_identification"]

        tableData = cimFields.filter(f => fieldsToFilter.includes(f.key))
    }
    else tableData = cimFields

    const tableCim = useMaterialReactTable({
        enableRowActions: true,
        positionActionsColumn: "last",
        getSubRows: (row) => row.subRows,
        enableExpanding: true,
        enableExpandAll: false,
        muiTableBodyCellProps: { sx: { borderRight: '1px solid #eee' } },
        displayColumnDefOptions: {
            'mrt-row-expand': {
                size: 50, //make the expand column wider
            },
        },
        renderRowActionMenuItems: ({ row, table, closeMenu }) => {
            return (
                <div>
                    {disabledFields.includes(row.original.key) ?
                        <Alert icon={<WarningOutlined fontSize="inherit" />} severity="warning">
                            Attributo non modificabile.
                        </Alert>
                        :
                        <EditFieldDialog closeMenu={closeMenu} label={t("cim." + row.original.key)} row={row.original} cimFields={cimFields} onUpdate={(rows) => updateCimField(rows)} />
                    }
                </div>
            )
        },
        initialState: {
            density: "compact",
            columnPinning: {
                right: ['mrt-row-actions'],
            },
            expanded: true
        },
        localization: MRT_Localization_IT,
        columns: cimKey,
        data: tableData,
        enableColumnResizing: true,
        layoutMode: "grid",
        enablePagination: false,
        muiTablePaperProps: {
            elevation: 0, //change the mui box shadow
            //customize paper styles
            sx: {
                // height: '80vh',
                overflow: 'auto'
            },
        },
    });


    const updateCimField = (rows) => {

        setMsgLoading("Modifica in corso");

        let cf = [...cimFields];
        let mcim = { ...modifiedCim };

        let modifiedObj = {};

        rows.forEach(el => {

            let modifiedItem = editCimField(el.row, el.value);
            modifiedObj = { ...modifiedObj, ...modifiedItem };

            const cfIndex = cf.findIndex(obj => obj.key === el.row.key);

            if (cfIndex !== -1) {
                cf[cfIndex].modified = el.value;
            }

            mcim.modified[el.row] = el.value;

        });

        updateProcess({ "id": selectedMail.id_process, "content_cim": { modified: modifiedObj } })
            .then(
                res => {
                    if (res && res.data) {
                        setCimFields(cf);
                        setModifiedCim(mcim);
                        //APERTURA SNACKBAR
                        if (!snackbarStatus) setSnackBarStatus(true)
                        setSnackBarMessage("Modifica Effettuata")
                        //}
                    }
                }
            )
            .catch(() => { /*ANNULLA*/ }).finally(() => setMsgLoading())
    }

    const editCimField = (row, value) => {
        let mcim = { ...modifiedCim };
        let key = row.key;

        // se è un sottoggetto
        if (row.parentName) {
            let mod = [];

            if (key !== null) { // se array oggetti
                mod[row.parentIndex] = {};
                mod[row.parentIndex][key] = value;
            }
            else //se array stringhe
                mod[row.parentIndex] = value;

            key = row.parentName;
            value = mod;
        }

        // aggiorno valore
        mcim.modified[key] = value;

        return { [key]: mcim.modified[key] };
    }

    useEffect(() => {
        if (selectedMail) {
            getMailDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sseClient) {
            console.log("avvio run_cim_extractor listener")
            sseClient.addEventListener(
                'run_cim_extractor', runCimExtractorListener)

            return () => {
                console.log("rimozione run_cim_extractor listener");
                sseClient.removeEventListener("run_cim_extractor", runCimExtractorListener);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sseClient]);

    const runCimExtractorListener = (event) => {
        if (event.data) {
            let data = JSON.parse(event.data);
            if (data.id === selectedMail.id && data.message.type_elaboration === 'cim') {
                if (data.message.in_elaboration === false || (data.message.in_elaboration === true && data.message.perc_avz >= 100)) {
                    refresh();
                } else {
                    setCimStatus(data.message.msg);
                }
            }
        }
    }

    const refresh = () => {
        setModifiedCim();
        setCimFields([]);
        setCimStatus("");
        getMailDetail();
    }

    const restartIACim = () => {
        confirm({
            cancellationText: "Annulla",
            cancellationButtonProps: { color: "inherit", variant: "outlined" },
            confirmationText: "Procedi",
            confirmationButtonProps: { color: "primary", variant: "contained" },
            description: <> Avviare la rielaborazione del CIM? <br /><br /> Attenzione, tutti i dati presenti verranno cancellati. </>,
            title: "Rielabora CIM"
        })
            .then(() => {
                startProcessIACim(selectedMail.id_mail).catch(() => { refresh() })
                //.finally(() => refresh());
            })
            .catch(() => { /*ANNULLA*/ });
    }

    const restartOrfeus = () => {
        confirm({
            cancellationText: "Annulla",
            cancellationButtonProps: { color: "inherit", variant: "outlined" },
            confirmationText: "Procedi",
            confirmationButtonProps: { color: "primary", variant: "contained" },
            description: <> Avviare la rilettura di ORFEUS? <br /><br /> Attenzione, tutti i dati presenti verranno cancellati. </>,
            title: "Rileggi ORFEUS"
        })
            .then(() => {
                setCimStatus("Rilettura ORFEUS in corso...")

                startProcessOrfeus(selectedMail.id_mail)
                .then(() => {refresh()})
                .catch(() => { refresh() })
                //.finally(() => refresh());
            }).catch(() => { /*ANNULLA*/ });
    }

    const restartCI = () => {
        confirm({
            cancellationText: "Annulla",
            cancellationButtonProps: { color: "inherit", variant: "outlined" },
            confirmationText: "Procedi",
            confirmationButtonProps: { color: "primary", variant: "contained" },
            description: <> Avviare il ricalcolo della codifica internazionale? <br /><br /> Attenzione, tutti i dati presenti verranno cancellati. </>,
            title: "Ricalcola Codifica Internazionale"
        })
            .then(() => {
                setCimStatus("Ricalcolo Codifica internazionale in corso...")

                startProcessCI(selectedMail.id_mail)
                    .then(() => {refresh()})
                    .catch(() => { refresh() })
                //.finally(() => refresh());
            })
            .catch(() => { /*ANNULLA*/ });
    }

    const getMailDetail = () => {
        setCimStatus("Caricamento dati...");

        getProcessMail(selectedMail.id_mail, activeStep).then(res => {
            if (res) {
                if (res.data) {
                    //refresh dello stato
                    let sm = { ...selectedMail };
                    sm.current_step = res.data.current_step;
                    if (res.data.id_process)
                        sm.id_process = res.data.id_process;
                    sm.available = false;
                    setSelectedMail(sm);
                }

                if (res.data && res.data.status_elaboration && res.data.status_elaboration.in_elaboration && res.data.status_elaboration.type_elaboration === "cim") {
                    setCimStatus(res.data.status_elaboration.msg);
                }

                else if (res.data && res.data.content_cim && res.data.content_cim.extracted) {
                    let fieldsCim = [];

                    let fields = res.data.content_cim.extracted;

                    setModifiedCim(res.data.content_cim);

                    //commento perchè possiamo editare tutto
                    //setDisabledFields(res.data.Cim_Editform_Disabled_Fields);
                    setDisabledFields([]);

                    Object.keys(fields).forEach(field => {
                        let subrows = [];

                        if (Array.isArray(fields[field])) {
                            fields[field].forEach((f, findex) => {

                                if (f instanceof Object) {
                                    for (const ff in f) {
                                        subrows.push({
                                            parentName: field,
                                            parentIndex: findex,

                                            key: ff,
                                            extracted: f[ff],
                                            orfeus: res.data.content_cim.orfeus && res.data.content_cim.orfeus[field] && res.data.content_cim.orfeus[field][findex] ? res.data.content_cim.orfeus[field][findex][ff] : "",
                                            normalized: res.data.content_cim.normalized && res.data.content_cim.normalized[field] && res.data.content_cim.normalized[field][findex] ? res.data.content_cim.normalized[field][findex][ff] : "",
                                            modified: res.data.content_cim.modified && res.data.content_cim.modified[field] && res.data.content_cim.modified[field][findex] ? res.data.content_cim.modified[field][findex][ff] : "",
                                            codificaInternazionale: res.data.content_cim.modified && res.data.content_cim.modified[field] && res.data.content_cim.modified[field][findex] ? Array.isArray(res.data.content_cim.modified[field][findex][ff]) : false,
                                        });
                                    }
                                } else {
                                    subrows.push({
                                        parentName: field,
                                        parentIndex: findex,

                                        key: null,
                                        extracted: f,
                                        orfeus: res.data.content_cim.orfeus && res.data.content_cim.orfeus[field] && res.data.content_cim.orfeus[field][findex] ? res.data.content_cim.orfeus[field][findex] : "",
                                        normalized: res.data.content_cim.normalized && res.data.content_cim.normalized[field] && res.data.content_cim.normalized[field][findex] ? res.data.content_cim.normalized[field][findex] : "",
                                        modified: res.data.content_cim.modified && res.data.content_cim.modified[field] && res.data.content_cim.modified[field][findex] ? res.data.content_cim.modified[field][findex] : "",
                                        codificaInternazionale: res.data.content_cim.modified && res.data.content_cim.modified[field] && res.data.content_cim.modified[field][findex] ? Array.isArray(res.data.content_cim.modified[field]) : false,
                                    });
                                }
                            });
                        }

                        fieldsCim.push({
                            key: field,
                            extracted: subrows.length > 0 ? "" : fields[field],
                            orfeus: subrows.length > 0 ? "" : res.data.content_cim.orfeus ? res.data.content_cim.orfeus[field] : "",
                            normalized: subrows.length > 0 ? "" : res.data.content_cim.normalized ? res.data.content_cim.normalized[field] : "",
                            modified: subrows.length > 0 ? "" : res.data.content_cim.modified ? res.data.content_cim.modified[field] : "",
                            codificaInternazionale: subrows.length > 0 ? false : Array.isArray(res.data.content_cim.modified[field]),
                            subRows: subrows
                        });

                    })

                    setCimFields(fieldsCim);
                    setCimStatus("OK");
                }
                else
                    setCimStatus("Dati CIM non disponibili.");
            }
        }).catch(() => { /*ANNULLA*/ });
    }

    // eslint-disable-next-line
    const anteprimaPdf = () => {
        generateCIMPdf().then(r => setPdfDialogOpen(true));
    }

    async function generateCIMPdf() {
        const formPdfBytes = await fetch("./files/cim.pdf").then((res) =>
            res.arrayBuffer(),
        ).catch(() => { /*ANNULLA*/ });

        const pdfDoc = await PDFDocument.load(formPdfBytes);
        const form = pdfDoc.getForm();

        for (const field in modifiedCim.modified) {
            try {
                let pdfInput = form.getTextField(field);
                if (modifiedCim.modified[field]) {
                    // eslint-disable-next-line
                    pdfInput.setText(modifiedCim.modified[field].replace(/[^\x00-\x7F]/g, ''));
                    pdfInput.setFontSize(4);
                }
            } catch (e) {
                console.error(e);
            }
        }

        form.flatten();
        // Step 4: Save the modified PDF.
        const pdfBytes = await pdfDoc.save();

        // Step 5: Create a `Blob` from the PDF bytes,
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });

        // Step 6: Create a download URL for the `Blob`.
        const url = URL.createObjectURL(blob);
        setCimPdf(url);

        // Step 7: Create a link element and simulate a click event to trigger the download.
        /*
        const link = document.createElement('a');
        link.href = url;
        link.download = 'filled_form.pdf';
        link.click();
        */

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarMessage("")
        setSnackBarStatus(false);
    };

    if (cimStatus === "OK") {
        return (<Box sx={{ m: 1 }}>
            {msgLoading && <BackdropLoading message={msgLoading} />}
            <Box sx={{ textAlign: "right" }}>
                <FormControlLabel control={<Checkbox color="info" onChange={(e) => { setCodificaInternazionale(e.target.checked); setOrfeusFilter(false) }} checked={codificaInternazionale} />} label="Codifica Internazionale" />
                <FormControlLabel control={<Checkbox color="info" onChange={(e) => { setOrfeusFilter(e.target.checked); setCodificaInternazionale(false) }} checked={orfeusFilter} />} label="ORFEUS" />

                <Tooltip title={t("tooltips.ricalcolaCodificaInternazionale")}>
                    <Button color="info" variant="contained" startIcon={<RefreshOutlined />} sx={{ mb: 2, mt: 2, mr: 1 }} onClick={() => restartCI()}>Ricalcola Cod. Int.</Button>
                </Tooltip>

                <Tooltip title={t("tooltips.rileggiOrfeus")}>
                    <Button color="info" variant="contained" startIcon={<RefreshOutlined />} sx={{ mb: 2, mt: 2, mr: 1 }} onClick={() => restartOrfeus()}>Rileggi ORFEUS</Button>
                </Tooltip>

                <Tooltip title={t("tooltips.rielaboraCim")}>
                    <Button color="info" variant="contained" startIcon={<RefreshOutlined />} sx={{ mb: 2, mt: 2, mr: 1 }} onClick={() => restartIACim()}>Rielabora CIM</Button>
                </Tooltip>
                {/*<Button variant='outlined' startIcon={<ArticleIcon />} sx={{ mb: 2, mt: 2 }} onClick={anteprimaPdf}>Anteprima Pdf CIM</Button>*/}
            </Box>

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarStatus}
                autoHideDuration={3000}
                key={"modifySuccess"}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <MaterialReactTable table={tableCim} />

            <Dialog maxWidth={"xl"} onClose={() => setPdfDialogOpen(false)} open={pdfDialogOpen}>
                <DialogTitle>Anteprima CIM</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setPdfDialogOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseOutlined />
                </IconButton>
                <DialogContent>
                    {cimPdf && <iframe title="cimPDF" style={{ width: "60vw", height: "80vh" }} src={cimPdf} />}
                </DialogContent>
            </Dialog>
        </Box>);
    }
    else {
        return <Box sx={{ m: 1 }}>
            {cimStatus === 'Dati CIM non disponibili.' ?
                <><Box sx={{ textAlign: "right" }}>
                    <Button variant='contained' startIcon={<RefreshOutlined />} sx={{ mb: 2, mt: 2, mr: 1 }} onClick={restartIACim}>Rielabora CIM</Button>
                </Box>
                    <Box sx={{ mt: 10, fontSize: 20, color: "black" }}>{cimStatus}</Box>
                </>
                :
                <Snackbar sx={{ mt: 20 }} open={true} anchorOrigin={{ horizontal: "center", vertical: "top" }}>
                    <Box width={400} sx={{ textAlign: "center" }}><LinearProgress />
                        <Box sx={{ mt: 10, fontSize: 20, color: "black" }}>{cimStatus}</Box></Box>
                </Snackbar>}
        </Box>
    }
}

export default ExtractionMailPage;