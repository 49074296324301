import { DownloadOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Tooltip } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_IT } from 'material-react-table/locales/it';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from "recoil";

import { getDownloadExcel, getDownloadXML, runReportSummary } from "../../client/apiClient";
import { selectedMailAtom } from "../../states/wizardState";
import BackdropLoading from "../utils/BackdropLoading";

const SummaryPage = () => {
    const selectedMail = useRecoilValue(selectedMailAtom);

    const [loading, setLoading] = useState(false);

    const [summaryStatus, setSummaryStatus] = useState("");
    const [cimSummaryFields, setCimSummaryField] = useState([]);
    const [distintaSummaryFields, setDistintaSummaryField] = useState([]);
    const [rdsSummaryFields, setRdsSummaryFields] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (selectedMail) {
            getSummaryDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadFile = () => {
        setLoading(true);

        getDownloadXML(selectedMail.id_mail).then(res => {
            if (res.data && res.data.requestXML) {
                const file = new Blob([res.data.requestXML], { type: 'application/xml' });
                const element = document.createElement("a");
                element.href = URL.createObjectURL(file);
                element.download = "summary_" + Date.now() + ".xml";
                document.body.appendChild(element);
                element.click();
                element.remove();
            }
        }).catch(() => { /*ANNULLA*/ }).finally(() => setLoading(false))
    }

    const downloadExcel = () => {
        setLoading(true);

        getDownloadExcel(selectedMail.id_mail).then(res => {
            if (res.data) {
                const element = document.createElement("a");
                element.href = URL.createObjectURL(res.data);
                element.download = "summary_" + Date.now() + ".xlsx";
                document.body.appendChild(element);
                element.click();
                element.remove();
            }
        }).catch(() => { /*ANNULLA*/ }).finally(() => setLoading(false))
    }

    const getSummaryDetail = () => {
        setSummaryStatus("Caricamento dati...");

        runReportSummary(selectedMail.id_mail).then(res => {
            if (res && res.data) {
                if(res.data.cim)
                    setCimSummaryField(res.data.cim);
                if(res.data.distinta)
                    setDistintaSummaryField(res.data.distinta);
                if(res.data.rds)
                    setRdsSummaryFields(res.data.rds);

                setSummaryStatus("OK");
            }
            else
                setSummaryStatus("Dati di riepilogo non disponibili.");
        }).catch(() => { /*ANNULLA*/ });
    }

    function getColumns() {
        return [
            {
                header: 'Messaggio',
                accessorKey: 'message',
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word' }}>{cell.getValue()}</Box>
                )
            },
            {
                header: 'Campi coinvolti',
                accessorKey: 'fields_involved',
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word' }}>{t(`cim.${cell.getValue()}`)}</Box>
                )
            },
            {
                header: 'Gravità',
                accessorKey: 'severity',
                size: 10,
                Cell: ({ cell }) => {
                    let severity = cell.getValue();
                    return <Alert severity={severity === 'alarm' ? 'error' : severity} sx={{padding: "2px", width:"100%"}}>{severity}</Alert>
                }
            },
        ]
    }

    if (summaryStatus === "OK") {
        return (
            <>
               {loading && <BackdropLoading message="Download ..." /> }
                <Box sx={{ m: 1 }}>
                    <Box sx={{ textAlign: "right" }}>
                        <Tooltip title={t("tooltips.downloadXml")}>
                            <Button color="info" variant="contained" startIcon={<DownloadOutlined />} sx={{ mb: 2, mt: 2, mr: 1 }} onClick={downloadFile}>Download XML</Button>
                        </Tooltip>
                        <Tooltip title={t("tooltips.downloadExcel")}>
                            <Button color="info" variant="contained" startIcon={<DownloadOutlined />} sx={{ mb: 2, mt: 2, mr: 1 }} onClick={downloadExcel}>Download Excel</Button>
                        </Tooltip>
                    </Box>
                    <Accordion defaultExpanded={true} key={"cim"}>
                        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>Riepilogo CIM</AccordionSummary>
                        <AccordionDetails>
                            <MaterialReactTable
                                muiTableBodyCellProps={{ sx: { borderRight: '1px solid #eee' } }}
                                localization={MRT_Localization_IT}
                                initialState={{
                                    density: "compact",
                                    columnPinning: {
                                        right: ['mrt-row-actions'],
                                    },
                                }}
                                enableColumnResizing
                                layoutMode="grid"
                                columns={getColumns()}
                                data={cimSummaryFields}
                                enablePagination={false}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded={true} key={"distinta"}>
                        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>Riepilogo distinta</AccordionSummary>
                        <AccordionDetails>
                            <MaterialReactTable
                                muiTableBodyCellProps={{ sx: { borderRight: '1px solid #eee' } }}
                                localization={MRT_Localization_IT}
                                initialState={{
                                    density: "compact",
                                    columnPinning: {
                                        right: ['mrt-row-actions'],
                                    },
                                }}
                                enableColumnResizing
                                layoutMode="grid"
                                columns={getColumns()}
                                data={distintaSummaryFields}
                                enablePagination={false}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded={true} key={"rds"}>
                        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>Riepilogo RdS</AccordionSummary>
                        <AccordionDetails>
                            <MaterialReactTable
                                muiTableBodyCellProps={{ sx: { borderRight: '1px solid #eee' } }}
                                localization={MRT_Localization_IT}
                                initialState={{
                                    density: "compact",
                                    columnPinning: {
                                        right: ['mrt-row-actions'],
                                    },
                                }}
                                enableColumnResizing
                                layoutMode="grid"
                                columns={getColumns()}
                                data={rdsSummaryFields}
                                enablePagination={false}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </>
        )
    }
}

export default SummaryPage;