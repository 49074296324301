import { EditOutlined } from '@mui/icons-material';
import Textarea from '@mui/joy/Textarea';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

export default function EditTargaUTIDialog(data) {
    const [open, setOpen] = React.useState(false);

    const [suggestedValPrefix, setSuggestedValPrefix] = React.useState("");
    const [suggestedValNumber, setSuggestedValNumber] = React.useState("");
    const [suggestedValCheckDigit, setSuggestedValDigit] = React.useState("");

    const fields = ['Prefix', 'Number', 'checkDigit'];

    const setSuggestedVal = (field, value) => {
        if (field === 'Prefix') {
            setSuggestedValPrefix(value);
        }
        else if (field === 'Number') {
            setSuggestedValNumber(value);
        }
        else {
            setSuggestedValDigit(value);
        }
    };

    const getSuggestedVal = (field) => {
        if (field === 'Prefix') {
            return suggestedValPrefix;
        }
        else if (field === 'Number') {
            return suggestedValNumber;
        }
        else {
            return suggestedValCheckDigit;
        }
    };

    React.useEffect(() => {

        fields.map(field => {
            let value = ""

            if (data.row.modified && data.row.modified[field] !== undefined && data.row.modified[field] !== null) {
                value = data.row.modified[field];
            }
            else if (data.row.orfeus && data.row.orfeus[field]) {
                value = data.row.orfeus[field];
            }
            else if (data.row.extracted && data.row.extracted[field]) {
                value = data.row.extracted[field];
            }

            setSuggestedVal(field, value);
        });

    }, [data])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formatData = (data) => {
        let value = ""

        switch (data) {
            case "":
                value = "Non valorizzato (empty string)"
                break;
            case null:
                value = "Non valorizzato (null value)"
                break;
            case undefined:
                value = "valore non definito (undefined value)"
                break;
            default:
                value = data
                break;
        }

        return value
    }

    return (
        <React.Fragment>
            <EditOutlined className='hover-icon' variant="text" onClick={handleClickOpen} />
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();

                        data.rowToUpdate.utiRola.key = 'TargaUTI';
                        data.onUpdate(data.rowToUpdate, [suggestedValPrefix, suggestedValNumber, suggestedValCheckDigit]);

                        handleClose();
                    },
                }}
            >
                <DialogTitle>Modifica Attributo {data.label}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Seleziona di seguito il valore corretto
                    </DialogContentText>

                    {fields.map(field =>
                        <>
                            <FormControl sx={{ mt: 3 }} fullWidth>
                                <InputLabel>Valori Suggeriti per {field}</InputLabel>
                                <Select
                                    sx={{ display: (data.row.extracted && data.row.extracted[field]) || (data.row.orfeus && data.row.orfeus[field]) }}
                                    label="Valori Suggeriti"
                                    name="suggestedValues"
                                >
                                    {data.row.extracted && <MenuItem onClick={(e) => setSuggestedVal(field, data.row.extracted[field])} disabled={data.row.extracted[field] === null || data.row.extracted[field] === undefined} value={data.row.extracted[field]}>{"Estrazione: " + (["", null, undefined].includes(data.row.extracted[field]) ? formatData(data.row.extracted[field]) : data.row.extracted[field])}</MenuItem>}
                                    {data.row.orfeus && <MenuItem onClick={(e) => setSuggestedVal(field, data.row.orfeus[field])} disabled={data.row.orfeus[field] === null || data.row.orfeus[field] === undefined} value={data.row.orfeus[field]}>{"Orfeus: " + (["", null, undefined].includes(data.row.orfeus[field]) ? formatData(data.row.orfeus[field]) : data.row.orfeus[field])}</MenuItem>}
                                </Select>
                            </FormControl>

                            <Textarea
                                sx={{ mt: 3 }}
                                autoFocus
                                // required
                                minRows={2}
                                size="md"
                                onChange={(e) => setSuggestedVal(field, e.target.value)}
                                value={getSuggestedVal(field)}
                            />
                        </>)}
                </DialogContent>
                <DialogActions>
                    <Button color='inherit' onClick={handleClose} variant='outlined'>Annulla</Button>
                    <Button variant='contained' type="submit">Modifica</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
