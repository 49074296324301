import dayjs from "dayjs";
import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist()

/* username */
export const userAtom = atom({
    key: 'userAtom',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

/* access token */
export const accessTokenAtom = atom({
    key: 'accessTokenAtom',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

/* account aad */
export const userAccountAtom = atom({
    key: 'userAccountAtom',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

export const wizardPageAtom = atom({
    key: 'wizardPageAtom',
    default: { id: 0, name: "Scelta Range Date" },
});

export const selectedMailAtom = atom({
    key: 'selectedMailAtom',
    default: null,
});

/* checkRID utilizzata per il controllo dei RID nella */
export const checkRIDAtom = atom({
    key: 'checkRIDAtom',
    default: 0
});

/* campi di ricerca */
export const fromDateAtom = atom({
    key: 'fromDateAtom',
    default: dayjs(),
});

export const toDateAtom = atom({
    key: 'toDateAtom',
    default: dayjs(),
});

export const searchTextAtom = atom({
    key: 'searchTextAtom',
    default: "",
});

export const exactTextAtom = atom({
    key: 'exactTextAtom',
    default: false,
});

/* */

// server sent events client
export const sse = atom({
    key: 'sseAtom',
    default: null
});


/* messaggi di errore */
export const errorMsg = atom({
    key: 'errorMsgAtom',
    default: null
});
/* messaggi di warning */
export const warningMsg = atom({
    key: 'warningMsgAtom',
    default: null
});