export const Config = {
  serviceUrls: {
    "API_BASE_PATH": "",
    "SSE_URL": "",
    "ACK_INTERVAL": "300000"
  },
  selectedAAD: "TEST",
  enableCredentialLogin: true,
  AAD: {
    "TEST": {
      clientId: "87f1a434-4b83-45c8-8f39-ccb9dac2fa8f",
      redirectUri: "http://localhost:3000",
      authority: "https://login.microsoftonline.com/70fc5a88-7c0f-42ad-9db2-35d1222673c6"
    },
    "SVILUPPO": {
      clientId: "87f1a434-4b83-45c8-8f39-ccb9dac2fa8f",
      redirectUri: "https://mercitalia.westeurope.cloudapp.azure.com",
      authority: "https://login.microsoftonline.com/70fc5a88-7c0f-42ad-9db2-35d1222673c6"
    },
    "CERTIFICAZIONE": {
      clientId: "853943dc-e222-4ecf-8cad-a86f9dd6f2ef",
      redirectUri: "https://ldvai-cert.mercitaliarail.it",
      authority: "https://login.microsoftonline.com/9d80a404-0678-4073-8aac-b12d15471f59"
    },
    "PRODUZIONE": {
      clientId: "6a5a5d62-695c-4f36-88c3-1cca65701c93",
      redirectUri: "https://ldvai.mercitaliarail.it",
      authority: "https://login.microsoftonline.com/4c8a6547-459a-4b75-a3dc-f66efe3e9c4e"
    }
  }
}