import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useRecoilState } from 'recoil';
import { CircularProgress, Stack } from '@mui/material';

import { sse } from './states/wizardState';
import { Config } from "./configApp";
import WizardPage from "./pages/wizard/Wizard";
import PageNotFound from "./pages/pageNotFound/pageNotFound";
import Logs from "./pages/logs/logs";

export default function App() {
  const [loadConfig, setloadConfig] = React.useState(false);
  const [sseClient, setSseClient] = useRecoilState(sse);

  React.useEffect(() => {
    if (sseClient) {
      sseClient.close();
    }

    setloadConfig(false);
    setSseClient(new EventSource(Config.serviceUrls.SSE_URL, { withCredentials: false }));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadConfig)
    return (<Stack alignItems="center" ><CircularProgress color="inherit" /></Stack>);
  else
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="" exact element={<WizardPage />} />
            <Route path="/logs" exact element={<Logs />} />
            <Route path="*" exact element={<PageNotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
}