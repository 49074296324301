import { useIsAuthenticated } from '@azure/msal-react';
import { DateRangeOutlined, FormatListBulletedOutlined, ForwardToInboxOutlined, LocalShippingOutlined, SendOutlined, TrainOutlined } from '@mui/icons-material';
import { Alert, Box, Grid, Snackbar } from '@mui/material';
import * as React from 'react';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import { Wizard, useWizard } from 'react-use-wizard';
import { useRecoilState, useRecoilValue } from "recoil";

import { ack, ackEmail } from '../../client/apiClient';
import { Config } from "../../configApp";
import logo from '../../logo.png';
import logoSmall from '../../logoSmall.png';
import { errorMsg, selectedMailAtom, userAtom, warningMsg, wizardPageAtom } from '../../states/wizardState';
import AssociationPage from '../association/extractionrds';
import DataRangePage from '../dateRange/datarange';
import SendEcnPage from '../ecn/sendEcn';
import ExtractionMailPage from '../extractionMail/extractionmail';
import ExtractionWagonsPage from '../extractionWagons/extractionWagons';
import LoginPage from '../login/Login';
import SummaryPage from '../summary/summary';
import Footer from './footer';
import Header from './header';

import './wizard.css';

const WizardPage = () => {
  const isAuthenticated = useIsAuthenticated();

  // eslint-disable-next-line
  const [user, setUser] = useRecoilState(userAtom);

  const [errorMessage, setErrorMessage] = useRecoilState(errorMsg);
  const [warningMessage, setWarningMessage] = useRecoilState(warningMsg);

  const [collapsed, setCollapsed] = React.useState(true);
  const [wizardPage, setWizardPage] = useRecoilState(wizardPageAtom);

  const wizardPages = [
    { id: 0, name: "Scelta Range Date", icon: <DateRangeOutlined />, page: DataRangePage },
    { id: 1, name: "Estrazione dati da email e CIM", icon: <ForwardToInboxOutlined />, page: ExtractionMailPage },
    { id: 2, name: "Estrazione dati distinta carri", icon: <TrainOutlined />, page: ExtractionWagonsPage },
    { id: 3, name: "Associazione LdV RdS", icon: <LocalShippingOutlined />, page: AssociationPage },
    { id: 4, name: "Riepilogo", icon: <FormatListBulletedOutlined />, page: SummaryPage },
    { id: 5, name: "Invio ECN", icon: <SendOutlined />, page: SendEcnPage }
  ]

  const selectedMail = useRecoilValue(selectedMailAtom);

  React.useEffect(() => {
    let interval;
    if (selectedMail && selectedMail.id) {
      interval = setInterval(() => ackEmail(selectedMail.id_mail), Config.serviceUrls.ACK_INTERVAL);
    }
    else {
      interval = setInterval(() => ack(), Config.serviceUrls.ACK_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [selectedMail]);

  return (!isAuthenticated && user == null) ? <LoginPage /> : <Grid container>
    <Box>

      <Snackbar open={errorMessage} anchorOrigin={{ horizontal: "center", vertical: "top" }} onClose={() => setErrorMessage()}>
        <Alert
          onClose={() => setErrorMessage()}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar open={warningMessage} anchorOrigin={{ horizontal: "center", vertical: "top" }} onClose={() => setWarningMessage()}>
        <Alert
          onClose={() => setWarningMessage()}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {warningMessage}
        </Alert>
      </Snackbar>

      <Sidebar
        collapsed={collapsed}
        onMouseOver={() => setCollapsed(false)}
        onMouseOut={() => setCollapsed(true)}
        className='sidebar'
        width='350px'
        collapsedWidth='80px'
      >
        {collapsed ?
          <img key={"logoSmall"} alt="Logo" style={{ height: "50px", width: "65px", paddingBottom: "20px" }} className='logoSmall' src={logoSmall} />
          :
          <img key={"logo"} alt="Logo" style={{ height: "50px", paddingBottom: "20px" }} className='logo' src={logo} />
        }
        <Menu>
          {wizardPages.map(wp =>
            <MenuItem
              className={wizardPage.id === wp.id ? "selected-step" : ""}
              disabled={wp.id !== 0 && (!selectedMail || wp.id > selectedMail.current_step)}
              icon={wp.icon}
              key={wp.id}
              onClick={() => { setWizardPage(wp) }}
            >
              {wp.name}
            </MenuItem>
          )}
        </Menu>
      </Sidebar>
    </Box>

    <Box className='wizard' key={"wizard"}>
      <Wizard
        startIndex={0}
        onStepChange={(stepIndex) => {
          if (wizardPage.id !== stepIndex) {
            setWizardPage({ id: stepIndex, name: wizardPages.find((obj) => obj.id === stepIndex).name })
          }
        }}
        header={<Header />}
        footer={<Footer />}>

        {wizardPages.map((wp, i) =>
          <Step Page={wp.page} key={"page" + i} />
        )}

      </Wizard>
    </Box>
  </Grid>
};

const Step = (props) => {
  const { activeStep, goToStep } = useWizard();

  const wizardPage = useRecoilValue(wizardPageAtom);

  React.useEffect(() => {
    if (wizardPage && wizardPage.id !== activeStep) {
      goToStep(wizardPage.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardPage])

  return (
    <Box className='wizardPage'>

      {<props.Page />}

    </Box>
  );
};

export default WizardPage;
