import { useRecoilValue } from "recoil";

import { useWizard } from "react-use-wizard";
import { selectedMailAtom, wizardPageAtom } from "../../states/wizardState";
import ProfileMenu from "./profileMenu";

export default function Header() {
  const selectedMail = useRecoilValue(selectedMailAtom);
  const pageState = useRecoilValue(wizardPageAtom);
  const { activeStep } = useWizard();

  return <div className='header'>
    <div className="headerTitle">
      {activeStep > 0 ? (pageState.name + " " + (selectedMail && selectedMail.subj ? (" -> " + selectedMail.subj) : ""))
        : pageState.name
      }
    </div>
    <ProfileMenu />
  </div>;
};